import React, { Component } from 'react';
import { getAuthByRole, updateAuthByRole } from '../../../api/role';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Checkbox, Button, message } from 'antd';
//@ts-ignore
import style from './index.module.scss';

@observer
class RoleAuth extends Component {

    @observable dataSource = [];
    @observable roleId = '';
    @observable loading = false;

    componentDidMount() {
        this.roleId = this.props.roleId;
        this.fetch();
    }

    componentWillReceiveProps(nextProps) {
        this.roleId = nextProps.roleId;
        this.fetch();
    }

    fetch = async () => {
        if (this.roleId !== '') {
            try {
                this.loading = true;
                const response = await getAuthByRole({ roleId: this.roleId });
                const { data } = response;
                if (data.code === 0) {
                    this.dataSource = data.data;
                }
            }
            finally {
                this.loading = false;
            }
        } else {
            this.dataSource = [];
        }
    }

    submit = async () => {
        if (this.roleId !== '') {
            try {
                this.loading = true;
                const response = await updateAuthByRole({
                    roleId: this.roleId,
                    menuIds: this.getCheckedMenuIds()
                });
                const { data } = response;
                if (data.code === 0) {
                    message.success("保存成功");
                    const { onAuthCanceled } = this.props;
                    onAuthCanceled && onAuthCanceled();
                }
            }
            finally {
                this.loading = false;
            }
        }
    }

    getCheckedMenuIds = () => {
        let menuIds = [];
        this.dataSource.forEach(d => {
            if (d.checked === 'true') {
                menuIds.push({
                    menuId: d.menuId,
                    readonly: d.readonly
                });
            }
        })
        return menuIds;
    }


    onMenuChecked = (checked, index) => {
        this.dataSource[index].checked = checked + '';
        this.dataSource[index].readonly = '0';
    }

    onReadonlyChecked = (checked, index) => {
        if (checked) {
            this.dataSource[index].readonly = '1';
        } else {
            this.dataSource[index].readonly = '0';
        }
    }

    render() {
        return (
            <div>
                {this.dataSource && (
                    this.dataSource.map((d, index) => {
                        return (
                            <div key={index} className={style.menuRow}>
                                <Checkbox
                                    value={d.menuId}
                                    checked={d.checked === 'true'}
                                    onChange={(e) => this.onMenuChecked(e.target.checked, index)}
                                >
                                    {d.menuName}
                                </Checkbox>
                                <Checkbox
                                    className={style.readonly}
                                    value={d.readonly}
                                    checked={d.readonly === '1'}
                                    disabled={d.checked !== 'true'}
                                    onChange={(e) => this.onReadonlyChecked(e.target.checked, index)}
                                >
                                    只读
                                </Checkbox>
                            </div>
                        );
                    })
                )}
                <div style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
                    <Button
                        type="primary"
                        style={{ minWidth: 100, marginTop: 20 }}
                        onClick={this.submit}
                        loading={this.loading}
                    >
                        确定
                    </Button>
                </div>
            </div>
        );
    }
}

export default RoleAuth;