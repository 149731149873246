import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
// @ts-ignore
import styles from './index.module.scss';
import { Popconfirm, Pagination, Empty, Spin } from 'antd';
import moment from 'moment';
import AuthLayer from '../../../component/authLayer';

@observer
class BatchTable extends Component {

    @observable
    dataSource = [];

    @observable
    pageNum = 1;

    @observable
    pageSize = 10;

    @observable
    total = 0;

    @observable
    loading = false;

    componentDidMount() {
        this.dataSource = this.props.dataSource;
        this.pageNum = this.props.pageNum;
        this.pageSize = this.props.pageSize;
        this.total = this.props.total;
        this.loading = this.props.loading;
    }

    componentWillReceiveProps(nextProps) {
        if (this.dataSource !== nextProps.dataSource) {
            this.dataSource = nextProps.dataSource;
        }
        if (this.pageNum !== nextProps.pageNum) {
            this.pageNum = nextProps.pageNum;
        }
        if (this.pageSize !== nextProps.pageSize) {
            this.pageSize = nextProps.pageSize;
        }
        if (this.total !== nextProps.total) {
            this.total = nextProps.total;
        }
        if (this.loading !== nextProps.loading) {
            this.loading = nextProps.loading;
        }
    }

    del = (batchNo) => {
        let { del } = this.props;
        del && del(batchNo);
    }

    modify = (batchNo) => {
        let { modify } = this.props;
        modify && modify(batchNo);
    }

    render() {
        let { pageNum, pageSize, total } = this;
        return (
            <div style={{ minWidth: '1200px' }}>
                <Spin spinning={this.loading}>
                    <div className={styles.table}>
                        <div className={styles.thead}>
                            {this.columns.map(item => {
                                return (
                                    <div key={item.key} style={{
                                        width: `${item.width}px`,
                                        // @ts-ignore
                                        textAlign: `${item.align}`
                                    }}>
                                        {item.title}
                                    </div>
                                );
                            })}
                        </div>
                        {this.dataSource.length > 0 ? (
                            <div>
                                {this.dataSource.length > 0 && (
                                    this.dataSource.map(item => {
                                        return (
                                            <div key={item.batchNo} className={styles.tr}>
                                                <div className={styles.batchNo}>{item.batchNo}</div>
                                                <div className={styles.td}>
                                                    {(item.children && item.children.length > 0) && (
                                                        <div>
                                                            {item.children.map((detail, index) => {
                                                                return (
                                                                    <div key={index} className={styles.row}>
                                                                        <div className={styles.furnaceNo}>{detail.furnaceNo}</div>
                                                                        <div className={styles.cell}>{detail.batchNumber}</div>
                                                                        <div className={styles.cell}>{detail.carbon}</div>
                                                                        <div className={styles.cell}>{detail.manganese}</div>
                                                                        <div className={styles.cell}>{detail.silicon}</div>
                                                                        <div className={styles.cell}>{detail.phosphorus}</div>
                                                                        <div className={styles.cell}>{detail.sulfur}</div>
                                                                        <div className={styles.cell}>{detail.vanadium}</div>
                                                                        <div className={styles.cell}>{detail.cep}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={styles.batchTime}>{moment(item.batchTime).format('YYYY-MM-DD hh:mm')}</div>
                                                <div className={styles.btn}>
                                                    {(item.deliver === 0) ? (
                                                        <AuthLayer>
                                                            <div className={styles.modify} onClick={() => this.modify(item.batchNo)}>
                                                                修改
                                                            </div>
                                                            <div className={styles.del}>
                                                                <Popconfirm
                                                                    title="确定要删除吗?"
                                                                    onConfirm={() => this.del(item.batchNo)}
                                                                    okText="是"
                                                                    cancelText="否"
                                                                >
                                                                    删除
                                                            </Popconfirm>
                                                            </div>
                                                        </AuthLayer>
                                                    ) : (<div className={styles.recordStatus}>已送检</div>)}
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        ) : (<Empty className={styles.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} />)}
                    </div>
                    {this.total > 0 && (
                        <div className={styles.pagination}>
                            <Pagination
                                current={pageNum}
                                pageSize={pageSize}
                                total={total}
                                showSizeChanger={true}
                                showTotal={(total) => { return `共${total}条` }}
                                onShowSizeChange={(current, size) => {
                                    let { onShowSizeChange } = this.props;
                                    onShowSizeChange && onShowSizeChange(current, size);
                                }}
                                onChange={(pagination) => {
                                    let { onTableChange } = this.props;
                                    onTableChange && onTableChange(pagination);
                                }}
                            />
                        </div>
                    )}
                </Spin>
            </div >
        );
    }

    columns = [
        {
            key: 'batchNo',
            width: 110,
            title: '轧钢批号',
            dataIndex: 'batchNo',
            align: 'left',
            render: (value, row, index) => {
                const obj = {
                    children: value,
                    props: {},
                };
                obj.props.rowSpan = row.count;
                return obj;
            }
        },
        {
            key: 'furnaceNo',
            title: '炉号',
            width: 110,
            dataIndex: 'furnaceNo',
        },
        {
            key: 'batchNumber',
            title: '支数',
            width: 80,
            dataIndex: 'batchNumber',
            align: 'center'
        },
        {
            key: 'carbon',
            title: '碳(C)',
            width: 80,
            dataIndex: 'carbon',
            align: 'center'
        },
        {
            key: 'manganese',
            title: '锰(Mn)',
            width: 80,
            dataIndex: 'manganese',
            align: 'center'
        },
        {
            key: 'silicon',
            title: '硅(Si)',
            width: 80,
            dataIndex: 'silicon',
            align: 'center'
        },
        {
            key: 'phosphorus',
            title: '磷(P)',
            width: 80,
            dataIndex: 'phosphorus',
            align: 'center'
        },
        {
            key: 'sulfur',
            title: '硫(S)',
            width: 80,
            dataIndex: 'sulfur',
            align: 'center'
        },
        {
            key: 'vanadium',
            title: '钒(V)',
            width: 80,
            dataIndex: 'vanadium',
            align: 'center'
        },
        {
            key: 'cep',
            title: 'ceq',
            width: 80,
            dataIndex: 'cep',
            align: 'center'
        },
        {
            key: 'batchTime',
            title: '组批时间',
            width: 180,
            dataIndex: 'batchTime',
            align: 'center',
        },
        {
            key: 'operation',
            title: '操作',
            align: 'center',
            width: 128,
        },
    ]
}

export default BatchTable;