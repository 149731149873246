import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
// @ts-ignore
import styles from './index.module.scss';

class ResetPassword extends Component {

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { userId } = this.props;
                let { resetPassword } = this.props;
                resetPassword && resetPassword({ userId, ...values });
                this.props.form.resetFields();
            }
        });
    };


    render() {
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        let { getFieldDecorator, getFieldsError } = this.props.form;
        return (
            <div>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <div className={styles.row}>
                        <Form.Item label='新密码'>
                            {getFieldDecorator('password',
                                {
                                    rules: [
                                        { required: true, message: '请输入新密码' },
                                        { min: 6, message: '密码最少6位' }
                                    ],
                                })
                                (<Input maxLength={20} style={{ width: 250 }} placeholder='请输入新密码' />)}
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', marginTop: 20, alignItems: 'center', justifyContent: 'center' }}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={this.hasErrors(getFieldsError()) || this.props.addSubmitting}
                                loading={this.props.addSubmitting}
                                style={{ minWidth: 100 }}
                            >
                                确定
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(ResetPassword);