import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import BilletSearch from './search';
import { Button, Table, message, Modal, InputNumber, Form, Popconfirm, Upload } from 'antd';
// @ts-ignore
import styles from './index.module.scss';
import moment from 'moment';
import { billetList, updateById, rejectBillet } from '../../api/billet';
import BilletGroup from './group';
import axios from 'axios';
import { getCookie, getEnv } from '../../utils/http';
import AuthLayer from '../../component/authLayer';

@observer
class Billet extends Component {

    @observable
    list = [];

    @observable
    loading = false;

    @observable
    acceptDialogMode = 0; //0关闭，1验收，2修改

    @observable
    groupShow = false;

    @observable
    selectedRowKeys = [];

    @observable
    groupBillets = [];

    acceptBillet = null;

    param = { leftNumberNotZero: true };

    @observable
    pageNum = 1;

    @observable
    pageSize = 10;

    @observable
    total = 0;

    componentDidMount() {
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    queryList = async (param) => {
        try {
            this.loading = true;
            param.deliver = 1; //只查看已交付的钢坯数据
            let resp = await billetList(param);
            let { data } = resp;
            if (data.code === 0) {
                this.list = data.data.list;
                this.pageNum = data.data.pageNum;
                this.pageSize = data.data.pageSize;
                this.total = data.data.total;
            }
        }
        finally {
            this.loading = false;
        }

    }

    updateBillet = async (param) => {
        let { acceptBillet, pageNum, pageSize } = this;
        if (acceptBillet) {
            try {
                this.loading = true;
                let resp = await updateById(param);
                let { data } = resp;
                if (data.code === 0) {
                    message.success('操作成功');
                    this.queryList({ pageNum, pageSize, ...this.param });
                }
            }
            finally {
                this.loading = false;
            }
        }
    }

    reject = async (billetId) => {
        let { pageNum, pageSize } = this;
        try {
            let resp = await rejectBillet(billetId);
            let { data } = resp;
            if (data.code === 0) {
                message.success('拒收成功');
                this.queryList({ pageNum, pageSize, ...this.param });
            }
            else {
                message.error('操作失败');
            }
        }
        finally {
            this.loading = false;
        }
    }

    exportAcceptance = async () => {
        this.loading = true;
        axios({
            method: "POST",
            url: getEnv() === 'DEV' ? `/billet/exportAcceptanceBilletList` : '/api/billet/exportAcceptanceBilletList',
            headers: {
                'Content-Type': 'application/json'
            },
            data: { ...this.param },
            responseType: 'blob'
        }).then((res) => {
            let blob = res.data;
            let url = window.URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = url;
            el.download = "钢坯验收数据模板.xlsx";
            el.hidden = true;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            this.loading = false;
        });
    }

    export = async () => {
        this.loading = true;
        axios({
            method: "POST",
            url: getEnv() === 'DEV' ? `/billet/exportBilletList` : '/api/billet/exportBilletList',
            headers: {
                'Content-Type': 'application/json'
            },
            data: { ...this.param },
            responseType: 'blob'
        }).then((res) => {
            let blob = res.data;
            let url = window.URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = url;
            el.download = "钢坯台账.xlsx";
            el.hidden = true;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            this.loading = false;
        });
    }


    search = (param) => {
        this.param = param;
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    onTableChange = (pagination) => {
        this.pageNum = pagination.current;
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    submitAcceptBillet = () => {
        let values = (this.props.form.getFieldsValue());
        let { acceptanceNumber } = values;
        let { acceptBillet } = this;
        acceptBillet.acceptanceNumber = acceptanceNumber;
        acceptBillet.leftNumber = acceptanceNumber;
        acceptBillet.acceptTime = moment().format('YYYY-MM-DD HH:mm:ss');
        this.updateBillet(acceptBillet);
        this.acceptBillet = null;
        this.acceptDialogMode = 0;
        this.props.form.resetFields();
    }

    groupOk = () => {
        this.groupShow = false;
        this.selectedRowKeys = [];
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    groupCancel = () => {
        this.groupShow = false;
    }

    getAcceptUploadProps = () => {
        let that = this;
        return (
            {
                name: 'file',
                action: getEnv() === 'DEV' ? `/billet/importBilletAcceptance` : '/api/billet/importBilletAcceptance',
                headers: {
                    authorization: `Bearer ${getCookie('token')}`,
                },
                onChange(info) {
                    if (info.file.status === 'uploading') {
                        that.loading = true;
                    }
                    if (info.file.status === 'done') {
                        message.success(`${info.file.response.msg}`)
                        let { pageSize, param } = that;
                        that.queryList({ pageNum: 1, pageSize, ...param });
                        that.loading = false;
                    } else if (info.file.status === 'error') {
                        that.loading = false;
                        message.error(`${info.file.name} 上传失败 ${info.file.response.msg}`);
                    }
                },
            }
        );
    }

    getBatchUploadProps = () => {
        let that = this;
        return (
            {
                name: 'file',
                action: getEnv() === 'DEV' ? `/billet/importBatch` : '/api/billet/importBatch',
                headers: {
                    authorization: `Bearer ${getCookie('token')}`,
                },
                onChange(info) {
                    if (info.file.status === 'uploading') {
                        that.loading = true;
                    }
                    if (info.file.status === 'done') {
                        message.success(`${info.file.response.msg}`)
                        let { pageSize, param } = that;
                        that.queryList({ pageNum: 1, pageSize, ...param });
                        that.loading = false;
                    } else if (info.file.status === 'error') {
                        that.loading = false;
                        message.error(`${info.file.name} 上传失败 ${info.file.response.msg}`);
                    }
                },
            }
        );
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.selectedRowKeys = selectedRowKeys;
                this.groupBillets = selectedRows;
            },
            getCheckboxProps: record => ({
                disabled: +record.leftNumber === 0,
            }),
        };
        let { pageNum, pageSize, total, acceptBillet } = this;
        let { getFieldDecorator } = this.props.form;
        let acceptProps = this.getAcceptUploadProps();
        let batchProps = this.getBatchUploadProps();
        return (
            <Fragment>
                <BilletSearch submit={this.search} />
                <div className={styles.tools}>
                    <AuthLayer>
                        <Button type='link' onClick={() => {
                            if (this.groupBillets.length > 0) {
                                this.groupShow = true;
                            }
                            else {
                                message.warn('您至少选择一炉钢坯');
                            }
                        }}>组批</Button>
                        <Button type='link' onClick={this.exportAcceptance}>导出验收数据模板</Button>
                        <Upload
                            {...acceptProps}
                            multiple={false}
                            accept={'xlsx'}
                            showUploadList={false}
                        >
                            <Button type='link'>导入验收数据</Button>
                        </Upload>
                    </AuthLayer>
                    <Button type='link' onClick={this.export}>导出钢坯数据</Button>
                    <AuthLayer>
                        <Upload
                            {...batchProps}
                            multiple={false}
                            accept={'xlsx'}
                            showUploadList={false}
                        >
                            <Button type='link'>导入组批数据</Button>
                        </Upload>
                    </AuthLayer>
                </div>
                <Modal
                    title='钢坯组批'
                    width={850}
                    visible={this.groupShow}
                    onCancel={() => { this.groupShow = false; }}
                    footer={null}
                >
                    <BilletGroup
                        groupOk={this.groupOk}
                        groupCancel={this.groupCancel}
                        billets={this.groupBillets}
                    />
                </Modal>
                <Modal
                    title={this.acceptDialogMode === 1 ? '验收钢坯' : '修改验收记录'}
                    width={800}
                    visible={this.acceptDialogMode !== 0}
                    onCancel={() => {
                        this.acceptDialogMode = 0;
                        this.acceptBillet = null;
                        this.props.form.resetFields();
                    }}
                    footer={null}
                >
                    <div className={styles.modalRow}>
                        <div className={styles.modalCol}>
                            炉号:
                            <span
                                className={styles.acceptField}
                                style={{ color: '#FF0033', fontWeight: 'bold' }}
                            >
                                {acceptBillet && acceptBillet.furnaceNo}
                            </span>
                        </div>
                        <div className={styles.modalCol}>
                            化验时间:
                            <span className={styles.acceptField}>
                                {acceptBillet && moment(acceptBillet.analysisTime).format('YYYY-MM-DD HH:mm')}
                            </span>
                        </div>
                    </div>
                    <div className={styles.modalRow}>
                        <div className={styles.modalCol}>
                            碳(C):
                            <span className={styles.acceptField}>
                                {acceptBillet && acceptBillet.carbon}
                            </span>
                        </div>
                        <div className={styles.modalCol}>
                            锰(Mn):
                            <span className={styles.acceptField}>
                                {acceptBillet && acceptBillet.manganese}
                            </span>
                        </div>
                        <div className={styles.modalCol}>
                            硅(Si):
                            <span className={styles.acceptField}>
                                {acceptBillet && acceptBillet.carbon}
                            </span>
                        </div>
                        <div className={styles.modalCol}>
                            磷(P):
                            <span className={styles.acceptField}>
                                {acceptBillet && acceptBillet.phosphorus}
                            </span>
                        </div>
                        <div className={styles.modalCol}>
                            硫(S):
                            <span className={styles.acceptField}>
                                {acceptBillet && acceptBillet.sulfur}
                            </span>
                        </div>
                        <div className={styles.modalCol}>
                            钒(V):
                            <span className={styles.acceptField}>
                                {acceptBillet && acceptBillet.vanadium}
                            </span>
                        </div>
                        <div className={styles.modalCol}>
                            ceq:
                            <span className={styles.acceptField}>
                                {acceptBillet && acceptBillet.cep}
                            </span>
                        </div>
                    </div>
                    <Form layout='inline'>
                        <div className={styles.modalRow} style={{ marginTop: '45px' }}>
                            <div className={styles.modalCol}>
                                <Form.Item label='验收数(支)'>
                                    {getFieldDecorator('acceptanceNumber',
                                        { initialValue: (acceptBillet && (acceptBillet.acceptanceNumber || acceptBillet.deliverNumber)) || 20 })
                                        (<InputNumber
                                            min={0}
                                            step={1}
                                            onPressEnter={this.submitAcceptBillet}
                                            autoFocus={true}
                                            precision={0}
                                        />)}
                                </Form.Item>
                            </div>
                            <div className={styles.modalCol}>
                                <Button
                                    type='primary'
                                    loading={this.loading}
                                    disabled={this.loading}
                                    onClick={this.submitAcceptBillet}
                                >
                                    确定
                             </Button>
                            </div>
                        </div>
                    </Form>

                </Modal>
                <Table
                    rowKey={record => record.billetId}
                    rowSelection={rowSelection}
                    columns={this.columns}
                    dataSource={this.list}
                    loading={this.loading}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total,
                        showSizeChanger: true,
                        showTotal: (total) => { return `共${total}条` },
                        onShowSizeChange: (current, size) => {
                            this.pageNum = current;
                            this.pageSize = size;
                            let { pageNum, pageSize } = this;
                            this.queryList({ pageNum, pageSize, ...this.param });
                        }
                    }}
                    onChange={this.onTableChange}
                />
            </Fragment>
        );
    }


    columns = [
        {
            key: 'furnaceNo',
            title: '炉号',
            dataIndex: 'furnaceNo',
        },
        {
            key: 'deliverNumber',
            title: '交付数(支)',
            dataIndex: 'deliverNumber',
            align: 'center'
        },
        {
            key: 'acceptanceNumber',
            title: '已验收(支)',
            dataIndex: 'acceptanceNumber',
            align: 'center'
        },
        {
            key: 'leftNumber',
            title: '待组批(支)',
            dataIndex: 'leftNumber',
            align: 'center'
        },
        {
            key: 'carbon',
            title: '碳(C)',
            dataIndex: 'carbon',
            align: 'center'
        },
        {
            key: 'manganese',
            title: '锰(Mn)',
            dataIndex: 'manganese',
            align: 'center'
        },
        {
            key: 'silicon',
            title: '硅(Si)',
            dataIndex: 'silicon',
            align: 'center'
        },
        {
            key: 'phosphorus',
            title: '磷(P)',
            dataIndex: 'phosphorus',
            align: 'center'
        },
        {
            key: 'sulfur',
            title: '硫(S)',
            dataIndex: 'sulfur',
            align: 'center'
        },
        {
            key: 'vanadium',
            title: '钒(V)',
            dataIndex: 'vanadium',
            align: 'center'
        },
        {
            key: 'cep',
            title: 'ceq',
            dataIndex: 'cep',
            align: 'center'
        },
        {
            key: 'analysisTime',
            title: '化验时间',
            dataIndex: 'analysisTime',
            align: 'center',
            render: (record) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div>{moment(record).format('YYYY-MM-DD HH:mm')}</div>
                    </div>
                );
            }
        },
        {
            key: 'operation',
            title: '操作',
            align: 'center',
            width: 100,
            render: (record) => {
                return (
                    <AuthLayer>
                        {record.acceptanceNumber <= record.leftNumber ? (
                            <div className={styles.modify}>
                                {record.acceptTime ? (
                                    <span onClick={() => {
                                        this.acceptBillet = record;
                                        this.acceptDialogMode = 2;
                                    }}>
                                        修改
                                    </span>
                                ) : (
                                        <div>
                                            <span onClick={() => {
                                                this.acceptBillet = record;
                                                this.acceptDialogMode = 1;
                                            }}
                                            >
                                                验收
                                            </span>
                                            <span style={{ color: '#FF0033', marginLeft: 8 }} >
                                                <Popconfirm
                                                    title="确定要拒收吗?"
                                                    onConfirm={() => this.reject(record.billetId)}
                                                    okText="是"
                                                    cancelText="否"
                                                >
                                                    拒收
                                                </Popconfirm>
                                            </span>
                                        </div>
                                    )}
                            </div>
                        ) : (<div style={{ color: '#cccccc' }}>已组批</div>)}
                    </AuthLayer>
                );
            }
        },
    ]
}

export default Form.create()(Billet);