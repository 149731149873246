import React, { Component } from 'react';
import styles from './index.module.scss';
import { Input, DatePicker, Button, Form, Switch } from 'antd';
import moment from 'moment';

class BilletSearch extends Component {

    onlyBatch = (value) => {
        let values = this.props.form.getFieldsValue();
        values.leftNumberNotZero = value;
        this.props.form.setFieldsValue({ ...values });
        this.search();
    }

    onlyAccept = (value) => {
        let values = this.props.form.getFieldsValue();
        values.notAccept = value;
        this.props.form.setFieldsValue({ ...values });
        this.search();
    }

    search = () => {
        let { submit } = this.props;
        let values = (this.props.form.getFieldsValue());
        let { furnaceNo, date, leftNumberNotZero ,notAccept} = values;
        let analysisStartTime, analysisEndTime;
        if (date) {
            analysisStartTime = moment(date[0]).format('YYYY-MM-DD') + ' 00:00:00';
            analysisEndTime = moment(date[1]).format('YYYY-MM-DD') + ' 23:59:59';
        }
        submit && submit({ furnaceNo, leftNumberNotZero, analysisStartTime, analysisEndTime,notAccept });
    }

    clear = () => {
        let { submit } = this.props;
        this.props.form.resetFields();
        submit && submit({ leftNumberNotZero: true });
    }

    render() {
        const { RangePicker } = DatePicker;
        let { getFieldDecorator } = this.props.form;
        return (
            <div className={styles.searchBox}>
                <Form layout='inline'>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Form.Item label='炉号'>
                                {getFieldDecorator('furnaceNo',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Input style={{ width: '180px' }} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='化验时间'>
                                {getFieldDecorator('date', { rules: [{ required: false, message: '' }], })
                                    (<RangePicker style={{ width: '240px' }} allowClear={false} />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='可组批'>
                                {getFieldDecorator('leftNumberNotZero',
                                    { valuePropName: 'checked', initialValue: true })
                                    (<Switch
                                        checkedChildren='是'
                                        unCheckedChildren='否'
                                        onChange={(e) => this.onlyBatch(e)}
                                    />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='可验收'>
                                {getFieldDecorator('notAccept',
                                    { valuePropName: 'checked', initialValue: false })
                                    (<Switch
                                        checkedChildren='是'
                                        unCheckedChildren='否'
                                        onChange={(e) => this.onlyAccept(e)}
                                    />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={this.search}
                                icon="search"
                                style={{ marginLeft: 8 }}
                            >
                                搜索
                            </Button>
                            <Button style={{ marginLeft: 16 }} onClick={this.clear}>
                                重置
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(BilletSearch);