import React, { Component } from 'react';
import styles from './index.module.scss';
import moment from 'moment';

class BilletWarranty extends Component {


    render() {
        let { warranty } = this.props;
        return (
            <div id={warranty.warrantySheetNo} className={styles.billetOutter}>
                <div className={`${'pdfpage'} ${styles['warrantyBox']}`} id={'w' + warranty.warrantySheetNo}>
                    <img
                        src='/sign.png'
                        alt='sign'
                        style={{
                            position: 'absolute',
                            right: 180,
                            bottom: 0,
                            width: 166,
                            height: 120,
                            zIndex: 9999,
                            opacity: 0.85
                        }}
                    />
                    <div className={styles.companyName}>{warranty.companyName}</div>
                    <div className={styles.title}>产品质量证明书</div>
                    <div className={styles.subTitle}>
                        {`证书编号: ${warranty.warrantySheetNo}`}
                    </div>
                    <div className={styles.table}>
                        <div className={styles.row}>
                            <div className={styles.td} style={{ width: 90 }}>产品名称</div>
                            <div className={styles.td} style={{ width: 160 }}>{warranty.productName}</div>
                            <div className={styles.td} style={{ width: 90 }}>收货单位</div>
                            <div className={styles.td} style={{ width: 510, borderRight: 0 }}>{warranty.consignee}</div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.td} style={{ width: 90 }}>检查依据</div>
                            <div className={styles.td} style={{ width: 160 }}>{warranty.inspectionStandard}</div>
                            <div className={styles.td} style={{ width: 90 }}>总重量(t)</div>
                            <div className={styles.td} style={{ width: 100 }}></div>
                            <div className={styles.td} style={{ width: 90 }}>车号</div>
                            <div className={styles.td} style={{ width: 320, borderRight: 0 }}>{warranty.licensePlate}</div>
                        </div>
                        <div className={styles.row} style={{ height: 44 }}>
                            <div className={styles.td} style={{ width: 90 }}>生产批号</div>
                            <div className={styles.td} style={{ width: 160 }}>牌号</div>
                            <div className={styles.td} style={{ width: 90 }}>
                                <div>规格</div>
                                <div>(mm)</div>
                            </div>
                            <div className={styles.td} style={{ width: 100 }}>
                                <div>长度</div>
                                <div>(mm)</div>
                            </div>
                            <div className={styles.td} style={{ width: 90 }}>支数</div>
                            <div className={styles.td} style={{ width: 320, padding: 0, borderRight: 0 }}>
                                <div className={styles.row}>
                                    <div className={styles.td} style={{ width: 320, borderRight: 0 }}>化学成分%</div>
                                </div>
                                <div className={styles.row} style={{ borderBottom: 0 }}>
                                    <div className={styles.td} style={{ width: 64 }}>C</div>
                                    <div className={styles.td} style={{ width: 64 }}>Mn</div>
                                    <div className={styles.td} style={{ width: 64 }}>Si</div>
                                    <div className={styles.td} style={{ width: 64 }}>P</div>
                                    <div className={styles.td} style={{ width: 64, borderRight: 0 }}>S</div>
                                </div>
                            </div>
                        </div>
                        {warranty.warrantySheetData.map((d, index) => {
                            return (
                                <div key={index} className={index === 6 ? styles.lastRow : styles.row}>
                                    <div className={styles.td} style={{ width: 90, textAlign: 'center', padding: 0 }}>{d.batchNo}</div>
                                    <div className={styles.td} style={{ width: 160 }}>{d.grade}</div>
                                    <div className={styles.td} style={{ width: 90 }}>{d.spec}</div>
                                    <div className={styles.td} style={{ width: 100 }}>{+d.length * 1000}</div>
                                    <div className={styles.td} style={{ width: 90 }}>{d.number}</div>
                                    <div className={styles.td} style={{ width: 64 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.carbon}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 64 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.manganese}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 64 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.silicon}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 64 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.phosphorus}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 64, borderRight: 0 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.sulfur}</div>);
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                        {(warranty.warrantySheetData && warranty.warrantySheetData.length < 7) && (
                            <div className={styles.blankBelow}>(以下空白)</div>
                        )}
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.footerRow}>
                            <div className={styles.footerLabel}>公司地址:</div>
                            <div style={{ width: 280 }}>{warranty.companyAddress}</div>
                        </div>
                        <div className={styles.footerRow} style={{ paddingLeft: 50 }}>
                            <div className={styles.footerLabel}>发货人:</div>
                            <div style={{ width: 300 }}>{warranty.curator}</div>
                            <div className={styles.footerLabel}>制表人:</div>
                            <div style={{ width: 160 }}>{warranty.editor}</div>
                            <div className={styles.footerLabel}>签发日期:</div>
                            <div>{moment(warranty.date).format('YYYY年MM月DD日')}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BilletWarranty;