// @ts-nocheck
import React, { Component, Fragment } from 'react';
import styles from './index.module.scss';
import { Spin, Carousel, Icon, message } from 'antd';
import BoundWarranty from './bound';
import DeformedWarranty from './deformed';
import BilletWarranty from './billet';
import html2canvas from 'html2canvas';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { warrantySheet } from '../../../api/stock';

const carouselConfig = {
    dots: true,
    lazyLoad: true,
    autoplay: false,
};

@observer
class Warranty extends Component {

    @observable loading = false;
    @observable warrantyList = [];
    currentSheet;

    componentDidMount() {
        this.fetchWarrantySheet();
    }

    fetchWarrantySheet = async () => {
        try {
            this.warrantyLoading = true;
            let response = await warrantySheet(this.props.match.params.stockId);
            let { data } = response;
            if (data.code === 0) {
                this.warrantyList = data.data;
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.warrantyLoading = false;
        }
    }

    //导出图片
    exportImage = () => {
        document.documentElement.scrollTop = 0;
        const newCanvas = document.createElement("canvas");
        const element = document.querySelector('#w' + this.currentSheet);
        const dom_width = parseInt(window.getComputedStyle(element).width);
        const dom_height = parseInt(window.getComputedStyle(element).height);
        //将canvas画布放大若干倍，然后盛放在较小的容器内，就显得不模糊了
        newCanvas.width = dom_width * 2 * window.devicePixelRatio;
        newCanvas.height = dom_height * 2 * window.devicePixelRatio;
        newCanvas.style.width = dom_width + "px";
        newCanvas.style.height = dom_height + "px";
        const context = newCanvas.getContext("2d");
        context.scale(2, 2);
        html2canvas(element, { canvas: newCanvas }).then((canvas) => {
            const imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
            const base64ToBlob = (code) => {
                let parts = code.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], { type: contentType });
            };
            const blob = base64ToBlob(imgUri);
            const fileName = this.currentSheet + '.png'
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, fileName);
            } else {
                const blobURL = window.URL.createObjectURL(blob)
                const vlink = document.createElement('a');
                vlink.style.display = 'none';
                vlink.href = blobURL;
                vlink.setAttribute('download', fileName);
                if (typeof vlink.download === 'undefined') {
                    vlink.setAttribute('target', '_blank');
                }
                document.body.appendChild(vlink);
                var evt = document.createEvent("MouseEvents");
                evt.initEvent("click", false, false);
                vlink.dispatchEvent(evt);
                document.body.removeChild(vlink);
                window.URL.revokeObjectURL(blobURL);
            }
        });
    }

    afterChange = (current) => {
        this.currentSheet = this.warrantyList[current].warrantySheetNo;
    }

    render() {
        let { warrantyList, loading } = this;
        if (warrantyList && warrantyList.length > 0) {
            this.currentSheet = warrantyList[0].warrantySheetNo;
        }
        return (
            <Spin spinning={loading}>
                {this.warrantyList.length > 0 && (
                    <Fragment>
                        <div className={styles.tools}>
                            <div
                                className={styles.btn}
                                onClick={() => { this.props.history.goBack(); }}
                                style={{ marginRight: 30, fontWeight: 'bold' }}
                            >
                                <Icon type="left" /> 返回
                            </div>
                            <div className={styles.desc}>{`本次共生成${warrantyList.length}张质保单，点击右侧按钮查看或下载`}</div>
                            <div className={styles.btns}>
                                <div
                                    className={styles.btn}
                                    onClick={() => { this.slider.slick.slickNext(); }}
                                    style={{ marginRight: 30 }}
                                >
                                    <Icon type="arrow-left" /> 上一张
                            </div>
                                <div
                                    className={styles.btn}
                                    onClick={() => { this.slider.slick.slickPrev(); }}
                                    style={{ marginRight: 50 }}
                                >
                                    下一张 <Icon type="arrow-right" />
                                </div>
                                {this.currentSheet && (
                                    <div
                                        className={styles.btn}
                                        onClick={this.exportImage}
                                        style={{ marginRight: 30 }}
                                    >
                                        下载
                                    </div>
                                )}
                            </div>
                        </div>
                        <Carousel
                            {...carouselConfig}
                            ref={el => (this.slider = el)}
                            afterChange={this.afterChange}
                        >
                            {warrantyList && warrantyList.map((warranty, index) => {
                                return (
                                    <div key={index} style={{ position: 'relative' }}>
                                        {warranty.productName === '热轧圆钢' && (<BoundWarranty warranty={warranty} />)}
                                        {warranty.productName === '热轧带肋钢筋' && (<DeformedWarranty warranty={warranty} />)}
                                        {warranty.productName === '连铸钢坯' && (<BilletWarranty warranty={warranty} />)}
                                    </div>
                                );
                            })}
                        </Carousel>
                    </Fragment>
                )}
            </Spin>
        );
    }
}

export default Warranty;