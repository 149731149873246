import React, { Component } from 'react';
import styles from './index.module.scss';
import { Input, DatePicker, Button, Form } from 'antd';
import moment from 'moment';

class StockSearch extends Component {

    search = () => {
        let { searchSubmit } = this.props;
        let values = (this.props.form.getFieldsValue());
        let { consignee, date, licensePlate, phone, batchNo ,warrantySheetNo} = values;
        let startTime, endTime;
        if (date) {
            startTime = moment(date[0]).format('YYYY-MM-DD') + ' 00:00:00';
            endTime = moment(date[1]).format('YYYY-MM-DD') + ' 23:59:59';
        }
        searchSubmit && searchSubmit({ consignee, licensePlate, phone, startTime, endTime, batchNo, warrantySheetNo });
    }

    clear = () => {
        let { searchSubmit } = this.props;
        this.props.form.resetFields();
        searchSubmit && searchSubmit({});
    }

    render() {
        const { RangePicker } = DatePicker;
        let { getFieldDecorator } = this.props.form;
        return (
            <div className={styles.searchBox}>
                <Form layout='inline'>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Form.Item label='收货单位'>
                                {getFieldDecorator('consignee',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Input style={{ width: '220px' }} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='车牌号'>
                                {getFieldDecorator('licensePlate',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Input style={{ width: '180px' }} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='手机号'>
                                {getFieldDecorator('phone',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Input style={{ width: '180px' }} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='出库时间'>
                                {getFieldDecorator('date', { rules: [{ required: false, message: '' }], })
                                    (<RangePicker style={{ width: '240px' }} allowClear />)}
                            </Form.Item>
                        </div>
                    </div>
                    <div className={styles.row}>
                    <div className={styles.col}>
                            <Form.Item label='质保单号'>
                                {getFieldDecorator('warrantySheetNo', { rules: [{ required: false, message: '' }], })
                                    (<Input style={{ width: '220px'}} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='炉批号'>
                                {getFieldDecorator('batchNo', { rules: [{ required: false, message: '' }], })
                                    (<Input style={{ width: '180px'}} allowClear />)}
                            </Form.Item>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={this.search}
                                icon="search"
                                style={{ marginLeft: 70, marginTop: 10 }}
                            >
                                搜索
                            </Button>
                            <Button style={{ marginLeft: 16, marginTop: 10 }} onClick={this.clear}>
                                重置
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(StockSearch);