import { request } from '../utils/http';

export const insertBatch = (param) => {
    return request(
        '/batch/insert',
        'POST',
        param
    );
};

export const batchList = (param) => {
    return request(
        '/batch/list',
        'POST',
        param
    );
};

export const delBatch = (batchNo) => {
    return request(
        '/batch/del',
        'POST',
        {batchNo}
    );
};

export const getByBatchNo = (batchNo) => {
    return request(
        '/batch/getByBatchNo',
        'POST',
        {batchNo}
    );
};

export const removeBillet = (batchId, billetId) => {
    return request(
        '/batch/removeBillet',
        'POST',
        {batchId, billetId}
    );
};

export const update = (param) => {
    return request(
        '/batch/update',
        'POST',
        param
    );
};

export const batchNoExist = (batchNo) => {
    return request(
        '/batch/batchNoExist',
        'POST',
        {batchNo}
    );
};

export const getLatestBatchNo = () => {
    return request(
        '/batch/getLatestBatchNo',
        'GET'
    );
};