import React, { Component } from 'react';
import styles from './index.module.scss';
import { Input, DatePicker, Button, Form, Select, message } from 'antd';
import moment from 'moment';
import { getGradeNameList } from '../../../api/grade';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

const Option = Select.Option;

@observer
class ComponentAnalysisSearch extends Component {

    @observable gradeList = [];
    @observable loading = false;

    componentDidMount() {
        this.fetchGradeList();
    }

    fetchGradeList = async () => {
        try {
            this.loading = true;
            const resp = await getGradeNameList();
            const { data } = resp;
            if (data.code === 0) {
                this.gradeList = data.data;
            } else {
                message.error('查询牌号出错');
            }
        } finally {
            this.loading = false;
        }
    }

    search = () => {
        let { submit } = this.props;
        let values = (this.props.form.getFieldsValue());
        let { furnaceNo, grade, date, deliver } = values;
        let analysisStartTime, analysisEndTime;
        if (date) {
            analysisStartTime = moment(date[0]).format('YYYY-MM-DD') + ' 00:00:00';
            analysisEndTime = moment(date[1]).format('YYYY-MM-DD') + ' 23:59:59';
        }
        submit && submit({ furnaceNo, grade, analysisStartTime, analysisEndTime, deliver });
    }

    clear = () => {
        let { submit } = this.props;
        this.props.form.resetFields();
        submit && submit({});
    }

    render() {
        const { RangePicker } = DatePicker;
        let { getFieldDecorator } = this.props.form;
        return (
            <div className={styles.searchBox}>
                <Form layout='inline'>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Form.Item label='炉号'>
                                {getFieldDecorator('furnaceNo',
                                    {
                                        rules: [{ required: false, message: '请输入炉号' }],
                                    })
                                    (<Input style={{ width: '180px' }} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='牌号'>
                                {getFieldDecorator('grade',
                                    {
                                        rules: [{ required: false, message: '请输入牌号' }],
                                    })
                                    (<Select
                                        style={{ width: 180 }}
                                        placeholder='请选择牌号'
                                        allowClear={true}
                                        loading={this.loading}
                                    >
                                        {(this.gradeList && this.gradeList.length > 0) && (
                                            this.gradeList.map((g, i) => {
                                                return (
                                                    <Option key={i} value={g}>{g}</Option>
                                                );
                                            })
                                        )}
                                    </Select>)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='状态'>
                                {getFieldDecorator('deliver',
                                    {
                                        rules: [{ required: false, message: '选择状态' }],
                                    })
                                    (<Select
                                        style={{ width: 110 }}
                                        placeholder='交付状态'
                                        allowClear={true}
                                    >
                                        <Option value='1'>已交付</Option>
                                        <Option value='0'>未交付</Option>
                                    </Select>)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='日期'>
                                {getFieldDecorator('date',
                                    {
                                        rules: [{ required: false, message: '请输入日期' }],
                                    })
                                    (<RangePicker style={{ width: '240px' }} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Button
                                style={{ marginLeft: 16 }}
                                type="primary"
                                htmlType="submit"
                                onClick={this.search}
                                icon="search"
                            >
                                搜索
                            </Button>
                            <Button style={{ marginLeft: 16 }} onClick={this.clear}>
                                重置
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(ComponentAnalysisSearch);