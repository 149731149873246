import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Input, Form, InputNumber, Select, DatePicker, Modal } from 'antd';
import styles from './index.module.scss';
import moment from 'moment';
import { billetProductExist } from '../../../api/product';

const { Option } = Select;

@observer
class AddProductBillet extends Component {

    @observable
    prodName = '钢坯';

    @observable
    show = false;

    @observable
    loading = false;

    @observable
    submitting = false;

    @observable
    batchNo = '';

    @observable
    prodTime = moment();

    @observable
    shift = '';

    @observable
    shiftList = [];

    @observable
    shiftListLoading = false;

    @observable
    batchNoExist = false;

    @observable
    tipsShow = false;

    @observable
    tips = '';


    componentDidMount() {
        this.loading = this.props.loading;
        this.shiftList = this.props.shiftList;
        if (this.shiftList && this.shiftList.length > 0) {
            this.shift = this.shiftList[0];
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.shiftList !== nextProps.shiftList) {
            this.shiftList = nextProps.shiftList;
            if (this.shiftList && this.shiftList.length > 0) {
                this.shift = this.shiftList[0];
            }
        }
        if (this.loading !== nextProps.loading) {
            this.loading = nextProps.loading;
        }
    }


    queryBatchNo = async () => {
        let { batchNo, prodName } = this;
        if (batchNo && batchNo !== '') {
            try {
                this.loading = true;
                let resp = await billetProductExist(batchNo, prodName);
                let { data } = resp;
                if (data.code === 0) {
                    this.batchNoExist = true;
                    this.tips = '';
                }
                else {
                    this.batchNoExist = false;
                    this.tips = data.msg;
                }
            }
            finally {
                this.loading = false;
            }
        }
    }

    onClose = () => {
        this.batchNo = '';
        let { drawerOnClose } = this.props;
        drawerOnClose && drawerOnClose();
    }

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.batchNo = this.batchNo;
                values.prodTime = moment(this.prodTime).format("YYYY-MM-DD");
                values.shift = this.shift;
                values.prodName = this.prodName;
                let { insertProduct } = this.props;
                insertProduct && insertProduct(values);
                this.props.form.resetFields();
                this.batchNo = '';
            }
        });
    };

    onBatchNoChange = (e) => {
        this.batchNo = e.target.value;
        this.queryBatchNo();
    }

    onProdTimeChange = (date, dateString) => {
        this.prodTime = date
    }

    onShiftChange = (value) => {
        this.shift = value;
    }

    onTipsShow = () => {
        this.tipsShow = !this.batchNoExist && this.batchNo !== '';
    }

    onTipsHidden = () => {
        this.tipsShow = false;
    }

    render() {
        let { show } = this.props;
        let { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title={`新增${this.prodName}成品记录`}
                width={1020}
                onCancel={this.onClose}
                visible={show}
                footer={null}
            >
                <div className={styles.addContent}>
                    <div className={styles.basicRow}>
                        <div className={styles.required}>*</div>
                        <div className={styles.basicLabel}>炉批号:</div>
                        <div className={styles.basicValue}>
                            <Input
                                style={{ width: 210 }}
                                maxLength={20}
                                allowClear
                                autoFocus={true}
                                value={this.batchNo}
                                onChange={this.onBatchNoChange}
                                placeholder='请输入炉批号'
                                onFocus={this.onTipsHidden}
                                onBlur={this.onTipsShow}
                            />
                            {this.tipsShow && (
                                <div className={styles.tips}>{this.tips}</div>
                            )}
                        </div>
                        <div className={styles.required}>*</div>
                        <div className={styles.basicLabel}>生产日期:</div>
                        <div className={styles.basicValue}>
                            <DatePicker
                                style={{ width: 210 }}
                                placeholder="请选择日期"
                                onChange={this.onProdTimeChange}
                                value={this.prodTime}
                                disabled={!this.batchNoExist}
                                allowClear={false}
                            />
                        </div>

                        <div className={styles.required}>*</div>
                        <div className={styles.basicLabel}>班次:</div>
                        <div className={styles.basicValue}>
                            <Select
                                value={this.shift}
                                style={{ width: 210 }}
                                loading={this.shiftListLoading}
                                disabled={!this.batchNoExist}
                                onChange={this.onShiftChange}
                            >
                                {(this.shiftList && this.shiftList.length > 0) && (
                                    this.shiftList.map((item, index) => {
                                        return (
                                            <Option key={index} value={item}>{item}</Option>
                                        );
                                    })
                                )}
                            </Select>
                        </div>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        <div className={styles.row}>
                            <div className={styles.raw}>
                                <div className={styles.subTitle}>成品</div>
                                <div className={styles.subRow}>
                                    <div className={styles.col}>
                                        <Form.Item label='规格(mm)'>
                                            {getFieldDecorator('spec',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: '150*150'
                                                })
                                                (<Input
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    allowClear
                                                />)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='长度(m)'>
                                            {getFieldDecorator('length',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 6
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={100}
                                                    step={1}
                                                    precision={2}
                                                    
                                                />)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='支数'>
                                            {getFieldDecorator('prodNumber',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 0
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={1}
                                                    precision={0}
                                                    
                                                />)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='重量'>
                                            {getFieldDecorator('prodWeight',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 0
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={0.001}
                                                    precision={3}
                                                    
                                                />)}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.prod}>
                                <div className={styles.subTitle}></div>
                                <div className={styles.subRow}>
                                    <div className={styles.col}>
                                        <Form.Item label='检验废(t)'>
                                            {getFieldDecorator('unqualified',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 0
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={0.001}
                                                    precision={3}
                                                    
                                                />)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='待处理(t)'>
                                            {getFieldDecorator('pendingDisposal',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 0
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={0.001}
                                                    precision={3}
                                                    
                                                />)}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.prodRight}>
                                <div className={styles.subTitle}>乱尺</div>
                                <div className={styles.subRow}>
                                    <div className={styles.col}>
                                        <Form.Item label='重量(t)'>
                                            {getFieldDecorator('disorderlyFeetWeight',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 0
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={0.001}
                                                    precision={3}
                                                />)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='捆数'>
                                            {getFieldDecorator('disorderlyFeetNumber',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 0
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={1}
                                                    precision={0}
                                                />)}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            

                        </div>
                        <div className={styles.btnRow}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: 80 }}
                                disabled={!this.batchNoExist && this.loading}
                                loading={this.loading}
                            >
                                确定
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        );
    }

}

export default Form.create()(AddProductBillet);;