

export const columns = [
    {
        title: '炉批号',
        width: 160
    },
    {
        title: '产品名称',
        width: 160,
    },
    {
        title: '牌号',
        width: 226,
    },
    {
        title: '规格',
        width: 194,
    },
    {
        title: '长度(m)',
        width: 208,
    },
    {
        title: '出库数量',
        width: 160,
    },
]