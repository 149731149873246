import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { message, Spin, Input, Button } from 'antd';
import { getSysConfigList, updateSysConfig } from '../../api/sys';
// @ts-ignore
import styles from './index.module.scss';

@observer
class SysConfig extends Component {

    @observable configList = [];
    @observable loading = false;

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        try {
            this.loading = true;
            let response = await getSysConfigList();
            let { data } = response;
            if (data.code === 0) {
                this.configList = data.data;
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.loading = false;
        }
    }

    update = async () => {
        try {
            this.loading = true;
            let response = await updateSysConfig(this.configList);
            let { data } = response;
            if (data.code === 0) {
                message.success("修改成功");
                this.fetch();
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.loading = false;
        }
    }

    onChange = (value, index) => {
        this.configList[index].value = value;
    }

    render() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                marginBottom: 20,
                minHeight: 200,
            }}>
                <Spin spinning={this.loading}>
                    {this.configList && this.configList.map((c, i) => {
                        return (
                            <div key={i} className={styles.row}>
                                <span className={styles.label}>{`${c.remark || ''}:`}</span>
                                <Input
                                    disabled={c.remark === '存储路径'}
                                    onChange={(e) => this.onChange(e.target.value, i)}
                                    value={c.value}
                                />
                            </div>
                        );
                    })}
                    {(this.configList && this.configList.length > 0) && (
                        <Button
                            type='primary'
                            onClick={this.update}
                            style={{ width: 80, marginLeft: 155, marginTop: 20, marginBottom: 20 }}
                        >
                            确定
                        </Button>
                    )}
                </Spin>
            </div>
        );
    }
}

export default SysConfig;