import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ClientsSearch from './search';
import AddClients from './add';
import ModifyClients from './modify';
import { Button, Table, message, Modal, Popconfirm } from 'antd';
import { getClients, insertClients, deleteClients, updateClients } from '../../api/customer';
// @ts-ignore
import styles from './index.module.scss';
import AuthLayer from '../../component/authLayer';

@observer
class Clients extends Component {

    @observable
    customerList = [];

    @observable
    loading = false;

    param = {
        customerName: '',
        phone: '',
    };

    @observable
    pageNum = 1;

    @observable
    pageSize = 10;

    @observable
    total = 0;

    @observable
    modifiedCustomer = {
        customerId: '',
        customerName: '',
        phone: '',
    };

    @observable addVisiable = false;
    @observable modifyVisiable = false;

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        let { customerName, phone } = this.param;
        try {
            this.loading = true;
            const resp = await getClients(this.pageNum, this.pageSize, customerName, phone);
            const { data } = resp;
            if (data.code === 0) {
                this.customerList = data.data.list;
                this.pageNum = data.data.pageNum;
                this.pageSize = data.data.pageSize;
                this.total = data.data.total;
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    insertClients = async (values) => {
        try {
            this.loading = true;
            const resp = await insertClients(values);
            const { data } = resp;
            if (data.code === 0) {
                message.success('新建成功');
                this.addVisiable = false;
                this.fetch();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    updateClients = async (values) => {
        try {
            this.loading = true;
            const resp = await updateClients(values);
            const { data } = resp;
            if (data.code === 0) {
                message.success('修改成功');
                this.modifyVisiable = false;
                this.fetch();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    deleteClients = async (customerId) => {
        try {
            this.loading = true;
            const resp = await deleteClients(customerId);
            const { data } = resp;
            if (data.code === 0) {
                message.success('删除成功');
                this.fetch();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    search = (customerName, phone) => {
        this.pageNum = 1;
        this.param.customerName = customerName;
        this.param.phone = phone;
        this.fetch();
    }

    onTableChange = (pagination) => {
        this.pageNum = pagination.current;
        this.fetch();
    }

    onAddClicked = () => {
        this.addVisiable = true;
    }

    onAddCanceled = () => {
        this.addVisiable = false;
    }

    onModifyClicked = (c) => {
        this.modifiedCustomer = c;
        this.modifyVisiable = true;
    }

    onModifyCanceled = () => {
        this.modifyVisiable = false;
        this.modifiedCustomer = {
            customerId: '',
            customerName: '',
            phone: '',
        };
    }

    render() {
        const { customerList, pageNum, pageSize, total } = this;
        return (
            <Fragment>
                <ClientsSearch submit={this.search} />
                <div style={{ marginBottom: 20 }}>
                    <AuthLayer>
                        <Button type='link' onClick={this.onAddClicked}>新增客户</Button>
                    </AuthLayer>
                </div>
                <Modal
                    title='新增客户'
                    width={500}
                    visible={this.addVisiable}
                    onCancel={this.onAddCanceled}
                    footer={null}
                >
                    <AddClients
                        // @ts-ignore
                        insertClients={this.insertClients}
                        addSubmitting={this.loading}
                    />
                </Modal>
                <Modal
                    title='修改客户'
                    width={500}
                    visible={this.modifyVisiable}
                    onCancel={this.onModifyCanceled}
                    footer={null}
                >
                    <ModifyClients
                        // @ts-ignore
                        modifyClients={this.updateClients}
                        addSubmitting={this.loading}
                        originCustomer={this.modifiedCustomer}
                    />
                </Modal>
                <Table
                    rowKey={record => record.customerId}
                    // @ts-ignore
                    columns={[...this.columns]}
                    dataSource={[...customerList]}
                    loading={this.loading}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total,
                        showSizeChanger: true,
                        showTotal: (total) => { return `共${total}条` },
                        onShowSizeChange: (current, size) => {
                            this.pageNum = current;
                            this.pageSize = size;
                            this.fetch();
                        }
                    }}
                    onChange={this.onTableChange}
                />
            </Fragment>
        );
    }

    columns = [
        {
            key: 'customerName',
            title: '收货单位',
            dataIndex: 'customerName',
        },
        {
            key: 'phone',
            title: '手机号',
            dataIndex: 'phone',
            width: 500,
        },
        {
            key: 'operation',
            title: '操作',
            align: 'center',
            width: 240,
            render: (record) => {
                return (
                    <AuthLayer>
                        <span className={styles.modify} onClick={() => this.onModifyClicked(record)}>修改</span>
                        <span className={styles.del}>
                            <Popconfirm
                                title="确定要删除吗?"
                                okText="是"
                                cancelText="否"
                                onConfirm={() => this.deleteClients(record.customerId)}
                            >
                                删除
                            </Popconfirm>
                        </span>
                    </AuthLayer>
                );
            }
        },
    ]
}

export default Clients;