

export const columns = [
    {
        title: '炉批号',
        width: '18%',
        align: ''
    },
    {
        title: '品名',
        width: '15%',
        align: ''
    },
    {
        title: '牌号',
        width: '19%',
        align: ''
    },
    {
        title: '规格',
        width: '18%',
        align: 'center'
    },
    {
        title: '长度',
        width: '14%',
        align: 'center'
    },
    {
        title: '出库数量',
        width: '16%',
        align: 'right'
    },
]