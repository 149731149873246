import React, { Component } from 'react';
import styles from './index.module.scss';
import { Input, DatePicker, Button, Form } from 'antd';
import moment from 'moment';

class MechSearch extends Component {

    search = () => {
        let { submit } = this.props;
        let values = (this.props.form.getFieldsValue());
        let { batchNo, furnaceNo, date } = values;
        let startTime, endTime;
        if (date) {
            startTime = moment(date[0]).format('YYYY-MM-DD') + ' 00:00:00';
            endTime = moment(date[1]).format('YYYY-MM-DD') + ' 23:59:59';
        }
        submit && submit({ batchNo, furnaceNo, startTime, endTime });
    }

    clear = () => {
        let { submit } = this.props;
        this.props.form.resetFields();
        submit && submit({});
    }

    render() {
        const { RangePicker } = DatePicker;
        let { getFieldDecorator } = this.props.form;
        return (
            <div className={styles.searchBox}>
                <Form layout='inline'>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Form.Item label='轧钢批号'>
                                {getFieldDecorator('batchNo',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Input style={{width: '180px'}} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='炉号'>
                                {getFieldDecorator('furnaceNo',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Input style={{width: '180px'}} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='日期'>
                                {getFieldDecorator('date',{rules: [{ required: false, message: '' }],})
                                    (<RangePicker style={{width: '240px'}} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={this.search}
                                icon="search"
                                style={{ marginLeft: 8 }} 
                            >
                                搜索
                            </Button>
                            <Button style={{ marginLeft: 16 }} onClick={this.clear}>
                                重置
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(MechSearch);