export const columns = [
    {
        title: '轧钢批号',
        dataIndex: 'batchNo',
        key: 'batchNo',
        align: 'center'
    },
    {
        title: '投料量',
        children: [
            {
                title: '支数',
                dataIndex: 'rawNumber',
                key: 'rawNumber',
                align: 'center'
            },
            {
                title: '重量(t)',
                dataIndex: 'rawWeight',
                key: 'rawWeight',
                align: 'center'
            },
        ]
    },
    {
        title: '成品',
        children: [
            {
                title: '规格',
                dataIndex: 'spec',
                key: 'spec',
                align: 'center',
                render: (record) => {
                    return (`⌀${record}`);
                }
            },
            {
                title: '长度',
                dataIndex: 'length',
                key: 'length',
                align: 'center',
                render: (record) => {
                    return (`${record}m`);
                }
            },
            {
                title: '捆数',
                dataIndex: 'prodNumber',
                key: 'prodNumber',
                align: 'center'
            },
            {
                title: '重量(t)',
                dataIndex: 'prodWeight',
                key: 'prodWeight',
                align: 'center'
            },
        ]
    },
    {
        title: '检验废(t)',
        dataIndex: 'unqualified',
        key: 'unqualified',
        align: 'center'
    },
    {
        title: '待处理(t)',
        dataIndex: 'pendingDisposal',
        key: 'pendingDisposal',
        align: 'center'
    },
    {
        title: '乱尺',
        children: [
            {
                title: '重量(t)',
                dataIndex: 'disorderlyFeetWeight',
                key: 'disorderlyFeetWeight',
                align: 'center'
            },
            {
                title: '捆数',
                dataIndex: 'disorderlyFeetNumber',
                key: 'disorderlyFeetNumber',
                align: 'center'
            },
        ]
    },
    {
        title: '生产日期',
        dataIndex: 'prodTime',
        key: 'prodTime',
        align: 'center'
    },
    {
        title: '已处理(t)',
        dataIndex: 'alreadyDisposal',
        key: 'alreadyDisposal',
        align: 'center'
    },
    {
        title: '班次',
        dataIndex: 'shift',
        key: 'shift',
        align: 'center'
    },
]