import React, { Component } from 'react';
import { InputNumber, Button, Spin, message, Select } from 'antd';
import { observer } from 'mobx-react';
import styles from './index.module.scss';
import { observable } from 'mobx';
import { mechDetail, updateMech } from '../../../api/mech';

const { Option } = Select;

@observer
class ModifyBound extends Component {

    @observable
    batchNo = '';

    @observable
    list = [];

    @observable
    loading = false;

    @observable
    submitting = false;

    componentDidMount() {
        this.batchNo = this.props.batchNo;
        this.fetch();
    }

    componentWillReceiveProps(nextProps) {
        if (this.batchNo !== nextProps.batchNo) {
            this.batchNo = nextProps.batchNo;
            this.fetch();
        }
    }

    fetch = async () => {
        if (this.batchNo && this.batchNo !== '') {
            try {
                this.loading = true;
                let resp = await mechDetail(this.batchNo);
                let { data } = resp;
                if (data.code === 0) {
                    this.list = data.data.children;
                }
                else {
                    message.error('查询错误');
                }
            }
            finally {
                this.loading = false;
            }
        }
    }


    changeRel = (value, index) => {
        this.list[index].rel = value;
    }

    changeRm = (value, index) => {
        this.list[index].rm = value;
    }

    changeA = (value, index) => {
        this.list[index].a = value;
    }

    changeColdBending = (value, index) => {
        this.list[index].coldBending = value;
    }

    changeAkv1 = (value, index) => {
        this.list[index].akv1 = value;
    }
    changeAkv2 = (value, index) => {
        this.list[index].akv2 = value;
    }
    changeAkv3 = (value, index) => {
        this.list[index].akv3 = value;
    }


    handleSubmit = async () => {
        if (this.list && this.list.length > 0) {
            for (let index in this.list) {
                let item = this.list[index];
                item.prodName = '圆钢';
                let rowNum = +index + 1;
                if (!item.rel || (item.rel + '').trim() === '') {
                    message.error(`第${rowNum}行：ReL为空`);
                    return;
                }
                if (!item.rm || (item.rm + '').trim() === '') {
                    message.error(`第${rowNum}行：Rm为空`);
                    return;
                }
                if (!item.a || (item.a + '').trim() === '') {
                    message.error(`第${rowNum}行：A为空`);
                    return;
                }
                if (!item.akv1 || (item.akv1 + '').trim() === '') {
                    message.error(`第${rowNum}行：akv为空`);
                    return;
                }
                if (!item.akv2 || (item.akv2 + '').trim() === '') {
                    message.error(`第${rowNum}行：akv为空`);
                    return;
                }
                if (!item.akv3|| (item.akv4 + '').trim() === '') {
                    message.error(`第${rowNum}行：akv为空`);
                    return;
                }
            }
            try {
                this.submitting = true;
                this.loading = true;
                let resp = await updateMech(this.list);
                let { data } = resp;
                if (data.code === 0) {
                    message.success('修改成功');
                }
                else {
                    message.error(data.msg);
                }
            }
            finally {
                this.submitting = false;
                this.batchNo = '';
                this.list = null;
                let { modifyCancel } = this.props;
                modifyCancel && modifyCancel();
            }
        }
    }

    render() {
        return (
            <div className={styles.modifyBox}>
                <Spin spinning={this.loading}>
                    <div className={styles.row}>
                        <div className={styles.label}>轧钢批号:</div>
                        <div className={styles.batchNo}>{this.batchNo}</div>
                    </div>
                    <div className={styles.table}>
                        <div className={styles.thead}>
                            {this.columns.map(item => {
                                return (
                                    <div key={item.key} style={{ width: `${item.width}px`, textAlign: `${item.align}` }}>
                                        {item.render ? (item.render) : (item.title)}
                                    </div>
                                );
                            })}
                        </div>
                        {this.list && (
                            this.list.map((item, index) => {
                                return (
                                    <div key={index} className={styles.tr}>
                                        <div className={styles.col} style={{ width: '120px' }}>{item.furnaceNo}</div>
                                        <div className={styles.col} style={{ width: '100px' }}>{item.grade}</div>
                                        <div style={{ width: '80px', textAlign: 'center' }}>
                                            <InputNumber
                                                style={{ width: '70px', textAlign: 'center' }}
                                                precision={0}
                                                step={1}
                                                min={0}
                                                max={1000}
                                                value={this.list[index].rel}
                                                onChange={(value) => { this.changeRel(value, index) }}
                                            />
                                        </div>
                                        <div style={{ width: '80px', textAlign: 'center' }}>
                                            <InputNumber
                                                style={{ width: '70px', textAlign: 'center' }}
                                                precision={0}
                                                step={1}
                                                min={0}
                                                max={1000}
                                                value={this.list[index].rm}
                                                onChange={(value) => { this.changeRm(value, index) }}
                                            />
                                        </div>
                                        <div style={{ width: '80px', textAlign: 'center' }}>
                                            <InputNumber
                                                style={{ width: '70px', textAlign: 'center' }}
                                                precision={1}
                                                step={0.1}
                                                min={0}
                                                max={100}
                                                value={this.list[index].a}
                                                onChange={(value) => { this.changeA(value, index) }}
                                            />
                                        </div>
                                        <div style={{ width: '80px', textAlign: 'center' }}>
                                            <Select
                                                style={{ width: '70px' }}
                                                defaultValue='完好'
                                                onChange={(value) => { this.changeColdBending(value, index) }}
                                            >
                                                <Option value="完好">完好</Option>
                                                <Option value="微裂纹">微裂纹</Option>
                                                <Option value="裂纹">裂纹</Option>
                                                <Option value="裂缝">裂缝</Option>
                                                <Option value="裂断">裂断</Option>
                                            </Select>
                                        </div>
                                        <div className={styles.akv} style={{ width: '270px', textAlign: 'center' }}>
                                            <InputNumber
                                                style={{ width: '70px', textAlign: 'center' }}
                                                precision={0}
                                                step={1}
                                                min={0}
                                                max={1000}
                                                value={this.list[index].akv1}
                                                onChange={(value) => { this.changeAkv1(value, index) }}
                                            />
                                            <div style={{ width: '10px' }}>-</div>
                                            <InputNumber
                                                style={{ width: '70px', textAlign: 'center' }}
                                                precision={0}
                                                step={1}
                                                min={0}
                                                max={1000}
                                                value={this.list[index].akv2}
                                                onChange={(value) => { this.changeAkv2(value, index) }}
                                            />
                                            <div style={{ width: '10px' }}>-</div>
                                            <InputNumber
                                                style={{ width: '70px', textAlign: 'center' }}
                                                precision={0}
                                                step={1}
                                                min={0}
                                                max={1000}
                                                value={this.list[index].akv3}
                                                onChange={(value) => { this.changeAkv3(value, index) }}
                                            />
                                        </div>

                                    </div>
                                );
                            })
                        )}
                    </div>
                    <div className={styles.btn}>
                        <Button
                            type='primary'
                            style={{ marginRight: '20px' }}
                            onClick={this.handleSubmit}
                            loading={this.submitting}
                            disabled={this.loading || this.submitting}
                        >
                            确定
                        </Button>
                        <Button
                            type='default'
                            onClick={() => {
                                this.batchNo = '';
                                this.list = [];
                                let { modifyCancel } = this.props;
                                modifyCancel && modifyCancel();
                            }}
                        >
                            取消
                        </Button>
                    </div>
                </Spin>
            </div >
        );
    }

    columns = [
        {
            key: 'furnaceNo',
            title: '炉号',
            dataIndex: 'furnaceNo',
            width: 120
        },
        {
            key: 'grade',
            title: '牌号',
            dataIndex: 'grade',
            width: 100
        },
        {
            key: 'rel',
            title: 'ReL(MPa)',
            dataIndex: 'rel',
            align: 'center',
            width: 80,
            render: (
                <div>
                    <div>ReL</div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>(MPa)</div>
                </div>
            )
        },
        {
            key: 'rm',
            title: 'Rm(MPa)',
            dataIndex: 'rm',
            align: 'center',
            width: 80,
            render: (
                <div>
                    <div>Rm</div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>(MPa)</div>
                </div>
            )
        },
        {
            key: 'a',
            title: 'A',
            dataIndex: 'a',
            align: 'center',
            width: 80,
            render: (
                <div>
                    <div>A</div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>(%)</div>
                </div>
            )
        },
        {
            key: 'coldBending',
            title: '弯曲性能',
            dataIndex: 'coldBending',
            align: 'center',
            width: 80,
            render: (
                <div>
                    <div>180°</div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>d=a</div>
                </div>
            )
        },
        {
            key: 'akv',
            title: 'akv',
            dataIndex: 'akv',
            align: 'center',
            width: 270,
            render: (
                <div>
                    <div>Akv(纵向)</div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>20°C(J)</div>
                </div>
            )
        },
    ]

}

export default ModifyBound;