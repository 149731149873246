import { request } from '../utils/http';

export const sendCode = (phone) => {
    return request(
        '/customer/sendCode',
        'POST',
        { phone }
    );
};

export const customerLogin = (phone, code) => {
    return request(
        '/customer/customerLogin',
        'POST',
        { phone, code }
    );
};

export const getClients = (pageNum, pageSize, customerName, phone) => {
    return request(
        '/customer/getCustomers',
        'POST',
        { pageNum, pageSize, customerName, phone }
    );
};

export const insertClients = (values) => {
    return request(
        '/customer/insertCustomer',
        'POST',
        values
    );
};

export const deleteClients = (customerId) => {
    return request(
        '/customer/deleteCustomer',
        'POST',
        { customerId }
    );
};

export const updateClients = (values) => {
    return request(
        '/customer/updateCustomer',
        'POST',
        values
    );
};

export const getPhonesByCustomer = (customerName) => {
    return request(
        '/customer/getPhonesByCustomer',
        'POST',
        { customerName }
    );
};