import axios from 'axios';

export const request = (api, method, params = {}, config = {}) => {
    const token = getCookie('token');
    const d = new Date();
    d.setTime(d.getTime() + (4 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = "token=" + token + "; " + expires;
    const data = (method === 'GET') ? 'params' : 'data';
    let headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
    if (config.headers) {
        headers = {
            ...headers,
            ...config.headers
        }
    }
    return new Promise((resolve, reject) => {
        axios({
            url: getEnv() === 'DEV' ? api : `/api${api}`,
            method,
            [data]: params,
            headers,
        }).then(resolve).catch(error => { reject(error); });
    });
};

export function getEnv() {
    let host = '';
    if (window.location.host.indexOf(':') !== -1) {
        host = window.location.host.split(':')[0];
    } else {
        host = window.location.host
    }
    if (host === 'localhost' || host === '127.0.0.1') {
        return 'DEV';
    } else {
        return 'PRD';
    }
}

export function getCookie(key) {
    const name = key + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        const c = ca[i].trim();
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}