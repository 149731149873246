import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Tabs, Button, message, Table, Modal, Popconfirm, Upload } from 'antd';
import styles from './index.module.scss';
import ProductSearch from './search'
import AddProduct from './add';
import ModifyProduct from './modify';
import { insertProduct, productList, delProduct, updateProduct } from '../../api/product';
import PrintProduct from './print';
import { columns } from './columns';
import { billetColumns } from './billetColumns';
import AddProductBillet from './addBilletProduct';
import { shiftList } from '../../api/shift';
import ModifyProductBillet from './modifyBilletProduct';
import { getCookie, getEnv } from '../../utils/http';
import axios from 'axios';
import AuthLayer from '../../component/authLayer';


const { TabPane } = Tabs;



@observer
class Product extends Component {

    @observable
    prodName = '圆钢';

    @observable
    add = false;

    @observable
    addBilletProduct = false;

    @observable
    pageNum = 1;

    @observable
    pageSize = 10;

    @observable
    total = 0;

    param = {};

    @observable
    loading = false;

    @observable
    list = [];

    @observable
    shiftList = [];

    @observable
    modifyShow = false;

    @observable
    modifyBilletProduct = false;

    @observable
    batchNo = '';

    @observable
    prodId = '';

    @observable
    rowDialog = false;

    rowDialogX = 0;
    rowDialogY = 0;

    @observable
    submitting = false;

    @observable
    modify = false;

    @observable
    printShow = false;

    componentDidMount() {
        this.fetch();
        this.fetchShift();
    }

    tabChange = (key) => {
        this.add = false;
        if (key === "1") {
            this.prodName = '圆钢';
        }
        if (key === "2") {
            this.prodName = '螺纹钢';
        }
        if (key === '3') {
            this.prodName = '钢坯';
        }
        this.fetch();
    }

    fetch = async () => {
        let { param, pageNum, pageSize } = this;
        this.initParam(param);
        try {
            this.loading = true;
            let resp = await productList({ pageNum, pageSize, ...param });
            let { data } = resp;
            if (data.code === 0) {
                this.list = data.data.list;
                this.pageNum = data.data.pageNum;
                this.total = data.data.total;
            }
            else {
                message.error('查询出错');
            }
        }
        finally {
            this.loading = false;
        }
    }

    initParam = (param) => {
        param.prodName = this.prodName;
        !param.batchNo && (param.batchNo = "");
        !param.spec && (param.spec = []);
        !param.startTime && (param.startTime = "");
        !param.endTime && (param.endTime = "");
        !param.shift && (param.shift = "");
    }

    search = (param) => {
        this.param = param;
        this.fetch();
    }

    insertProduct = async (param) => {
        if (param) {
            try {
                this.loading = true;
                let resp = await insertProduct(param);
                let { data } = resp;
                if (data.code === 0) {
                    message.success('新建成功');
                    this.drawerClose()
                }
                else {
                    message.error(data.msg);
                }
            }
            finally {
                this.loading = false;
            }
        }
    }

    modifyProduct = async (param) => {
        if (param) {
            try {
                this.loading = true;
                let resp = await updateProduct(param);
                let { data } = resp;
                if (data.code === 0) {
                    message.success('修改成功');
                    this.drawerClose()
                    return true;
                }
                else {
                    message.error(data.msg);
                }
            }
            finally {
                this.loading = false;
            }
        }
        return false;
    }

    del = async () => {
        if (this.batchNo && this.batchNo !== '') {
            try {
                this.submitting = true;
                let resp = await delProduct(this.batchNo);
                let { data } = resp;
                if (data.code === 0) {
                    message.success('删除成功');
                    this.rowDialog = false;
                    this.batchNo = '';
                    this.fetch();
                } else {
                    message.error(data.msg);
                }
            }
            finally {
                this.submitting = false;
            }
        }
    }

    drawerClose = () => {
        this.add = false;
        this.addBilletProduct = false;
        this.modifyShow = false;
        this.modifyBilletProduct = false;
        this.fetch();
    }

    addDrawerOpen = () => {
        if (this.prodName !== '钢坯') {
            this.add = true;
        }
        else {
            this.addBilletProduct = true;
        }

    }

    onShowSizeChange = (current, size) => {
        this.pageNum = current;
        this.pageSize = size;
        this.fetch();
    }

    onTableChange = (pagination) => {
        this.pageNum = pagination.current;
        this.fetch();
    }

    submit = (param) => {
        this.param = param;
        this.fetch();
    }

    resetModifyValues = () => {
        this.prodId = '';
        this.batchNo = '';
    }

    fetchShift = async () => {
        try {
            this.shiftListLoading = true;
            let resp = await shiftList();
            let { data } = resp;
            this.shiftList = data.data;
        }
        finally {
            this.shiftListLoading = false;
        }
    }

    export = async () => {
        this.loading = true;
        let { param, prodName } = this;
        param.prodName = this.prodName;
        axios({
            method: "POST",
            url: getEnv() === 'DEV' ? `/product/exportProduct` : '/api/product/exportProduct',
            headers: {
                'Content-Type': 'application/json'
            },
            data: { ...param },
            responseType: 'blob'
        }).then((res) => {
            let blob = res.data;
            let url = window.URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = url;
            el.download = `${prodName}成品台账.xlsx`;
            el.hidden = true;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            this.loading = false;
        });
    }

    getUploadProps = () => {
        let that = this;
        return (
            {
                name: 'file',
                action: getEnv() === 'DEV' ? `/product/importProduct` : `/api/product/importProduct`,
                headers: {
                    authorization: `Bearer ${getCookie('token')}`,
                },
                onChange(info) {
                    if (info.file.status === 'uploading') {
                        that.loading = true;
                    }
                    if (info.file.status === 'done') {
                        message.success(`${info.file.response.msg}`)
                        that.fetch();
                        that.loading = false;
                    } else if (info.file.status === 'error') {
                        that.loading = false;
                        message.error(`${info.file.name} 上传失败 ${info.file.response.msg}`);
                    }
                },
            }
        );
    }

    render() {
        let { pageNum, pageSize, total } = this;
        let uploadProps = this.getUploadProps();
        return (
            <Fragment>
                <Tabs defaultActiveKey="1" onChange={this.tabChange}>
                    <TabPane tab="圆钢" key="1" />
                    <TabPane tab="螺纹钢" key="2" />
                    <TabPane tab="钢坯" key="3" />
                </Tabs>
                <ProductSearch
                    prodName={this.prodName}
                    search={this.search}
                    shiftList={this.shiftList}
                />
                <div className={styles.tools}>
                    <AuthLayer>
                        <Button type='link' onClick={this.addDrawerOpen}>新增</Button>
                        <Upload
                            {...uploadProps}
                            multiple={false}
                            accept={'xlsx'}
                            showUploadList={false}
                        >
                            <Button type='link'>导入成品数据</Button>
                        </Upload>
                    </AuthLayer>
                    <Button type='link' onClick={this.export}>导出数据</Button>
                </div>
                <AddProduct
                    show={this.add}
                    drawerOnClose={this.drawerClose}
                    prodName={this.prodName}
                    insertProduct={this.insertProduct}
                    shiftList={this.shiftList}
                />
                <AddProductBillet
                    show={this.addBilletProduct}
                    drawerOnClose={this.drawerClose}
                    insertProduct={this.insertProduct}
                    shiftList={this.shiftList}
                />
                <ModifyProduct
                    show={this.modifyShow}
                    drawerOnClose={this.drawerClose}
                    prodName={this.prodName}
                    prodId={this.prodId}
                    modifyProduct={this.modifyProduct}
                    resetModifyValues={this.resetModifyValues}
                    shiftList={this.shiftList}
                />
                <ModifyProductBillet
                    show={this.modifyBilletProduct}
                    drawerOnClose={this.drawerClose}
                    prodId={this.prodId}
                    modifyProduct={this.modifyProduct}
                    resetModifyValues={this.resetModifyValues}
                    shiftList={this.shiftList}
                />
                <Table
                    rowKey={record => record.prodId}
                    columns={this.prodName === '钢坯' ? billetColumns : columns}
                    dataSource={this.list}
                    bordered
                    loading={this.loading}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total,
                        showSizeChanger: true,
                        showTotal: (total) => { return `共${total}条` },
                        onShowSizeChange: (current, size) => {
                            this.pageNum = current;
                            this.pageSize = size;
                            this.fetch();
                        }
                    }}
                    onRow={record => {
                        return {
                            onClick: event => {
                                this.batchNo = record.batchNo;
                                this.prodId = record.prodId;
                                this.rowDialog = true;
                                let mouseX = event.clientX;
                                let mouseY = event.clientY;
                                let screenX = document.body.offsetWidth;
                                let screenY = document.body.offsetHeight;
                                if (mouseX + 320 < screenX) {
                                    this.rowDialogX = mouseX;
                                } else {
                                    this.rowDialogX = mouseX - 300;
                                }
                                if (mouseY + 75 < screenY) {
                                    this.rowDialogY = mouseY;
                                } else {
                                    this.rowDialogY = mouseY - 70;
                                }
                            }
                        };
                    }}
                    onChange={this.onTableChange}
                />
                <Modal
                    title='打印预览'
                    width={950}
                    visible={this.printShow}
                    onCancel={() => { this.printShow = false }}
                    footer={null}
                >
                    <PrintProduct
                        batchNo={this.batchNo}
                        onCancel={() => { this.printShow = false }}
                    />
                </Modal>
                <Modal
                    mask={false}
                    closable={false}
                    width={300}
                    style={{
                        position: "absolute",
                        top: this.rowDialogY,
                        left: this.rowDialogX
                    }}
                    visible={this.rowDialog}
                    onCancel={() => { this.rowDialog = false; }}
                    footer={null}
                >
                    <div className={styles.btns}>
                        <div
                            className={styles.print}
                            onClick={() => {
                                this.printShow = true;
                                this.rowDialog = false;
                            }}
                        >
                            打印
                        </div>
                        <AuthLayer>
                            <div
                                className={styles.modify}
                                onClick={() => {
                                    if (this.prodName !== '钢坯') {
                                        this.modifyShow = true;
                                    }
                                    else {
                                        this.modifyBilletProduct = true;
                                    }
                                    this.rowDialog = false;
                                }}
                            >
                                修改
                            </div>
                            <Popconfirm
                                title="确定要删除吗?"
                                onConfirm={() => this.del()}
                                okText="是"
                                cancelText="否"
                            >
                                <div className={styles.del}>删除</div>
                            </Popconfirm>
                        </AuthLayer>

                    </div>
                </Modal>
            </Fragment>
        );
    }
}

export default Product;