import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, message, Spin } from 'antd';
import styles from './index.module.scss';
import { printProduct } from '../../../api/product';
import ReactToPrint from 'react-to-print';

@observer
class PrintProduct extends Component {

    @observable
    batchNo = '';

    @observable
    printData = {};

    @observable
    loading = false;

    componentRef = null;

    componentDidMount() {
        this.batchNo = this.props.batchNo;
        this.fetch();
    }

    componentWillReceiveProps(nextProps) {
        if (this.batchNo !== nextProps.batchNo) {
            this.batchNo = nextProps.batchNo;
            this.fetch();
        }
    }

    fetch = async () => {
        let { batchNo } = this;
        if (batchNo && batchNo !== '') {
            try {
                this.loading = true;
                let resp = await printProduct(batchNo);
                let { data } = resp;
                if (data.code === 0) {
                    this.printData = data.data;
                }
                else {
                    message.error(data.msg);
                }
            }
            finally {
                this.loading = false;
            }
        }
    }

    cancel = () => {
        let { onCancel } = this.props;
        onCancel && onCancel();
    }

    render() {
        return (
            <div className={styles.box}>
                <div className={styles.productLabel} ref={el => (this.componentRef = el)}>
                    <div className={styles.title}>山西兴华钢铁有限公司</div>
                    <Spin spinning={this.loading}>
                        <div className={styles.content}>
                            <div style={{ width: 378 }}>
                                <div className={styles.row}>
                                    <div className={styles.cnitemTitle}>名称</div>
                                    <div className={styles.enitemTitle}>APPELLATION</div>
                                    <div className={styles.itemValue}>{this.printData.productName}</div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.cnitemTitle}>执行标准</div>
                                    <div className={styles.enitemTitle}>STANDARD GB/T 702</div>
                                    <div className={styles.itemValue}>2017</div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.cnitemTitle}>牌号</div>
                                    <div className={styles.enitemTitle}>GRADE</div>
                                    <div className={styles.itemValue}>{this.printData.grade}</div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.cnitemTitle}>炉批号</div>
                                    <div className={styles.enitemTitle}>ROLLING NO.</div>
                                    <div className={styles.itemValue}>{this.printData.batchNo}</div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.cnitemTitle}>规格</div>
                                    <div className={styles.enitemTitle}>DIMENSION</div>
                                    <div className={styles.itemValue}>{this.printData.spec}</div>
                                </div>

                                {this.printData.productName === '连铸钢坯' ? (
                                    <div className={styles.row}>
                                        <div className={styles.cnitemTitle}>长度</div>
                                        <div className={styles.enitemTitle}>LENGTH</div>
                                        <div className={styles.itemValue}>{this.printData.length}</div>
                                    </div>
                                ) : (
                                        <div className={styles.row}>
                                            <div className={styles.cnitemTitle}>重量/支数</div>
                                            <div className={styles.enitemTitle}>WEIGHT/PIECES</div>
                                            <div className={styles.itemValue}>{this.printData.weight}</div>
                                        </div>
                                    )}

                                <div className={styles.row}>
                                    <div className={styles.cnitemTitle}>日期</div>
                                    <div className={styles.enitemTitle}>DATE</div>
                                    <div className={styles.itemValue}>{this.printData.date}</div>
                                </div>
                            </div>
                            <div className={styles.qrBox}>
                                <img alt="qr" style={{ width: '70px', height: '70px' }} src={this.printData.qr} />
                                <div className={styles.tips}>微信扫码获取质保书</div>
                            </div>
                        </div>
                    </Spin>
                </div>
                <div className={styles.btnRow}>
                    <ReactToPrint
                        trigger={() => (
                            <Button
                                type="primary"
                                style={{ width: 80, marginRight: 20 }}
                                disabled={this.loading}
                                loading={this.loading}
                            >
                                确定
                            </Button>)}
                        content={() => this.componentRef}
                    />
                    <Button
                        type="default"
                        style={{ width: 80 }}
                        onClick={this.cancel}
                    >
                        取消
                     </Button>
                </div>
            </div>
        );
    }

}

export default PrintProduct;