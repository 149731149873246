import React, { Component } from 'react';
// @ts-ignore
import styles from './index.module.scss';
import { Input, DatePicker, Button, Form, Select } from 'antd';
import moment from 'moment';

const Option = Select.Option;

class ProductModifyRecordSearch extends Component {

    search = () => {
        let { submit } = this.props;
        let values = (this.props.form.getFieldsValue());
        let { prodName, date, batchNo, user } = values;
        let startTime, endTime;
        if (date) {
            startTime = moment(date[0]).format('YYYY-MM-DD') + ' 00:00:00';
            endTime = moment(date[1]).format('YYYY-MM-DD') + ' 23:59:59';
        }
        submit && submit({ batchNo, prodName, startTime, endTime, user });
    }

    clear = () => {
        let { submit } = this.props;
        this.props.form.resetFields();
        submit && submit({});
    }

    render() {
        const { RangePicker } = DatePicker;
        let { getFieldDecorator } = this.props.form;
        return (
            <div className={styles.searchBox}>
                <Form layout='inline'>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Form.Item label='品名'>
                                {getFieldDecorator('prodName',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Select style={{ width: '150px' }} allowClear >
                                        <Option value='圆钢'>圆钢</Option>
                                        <Option value='螺纹钢'>螺纹钢</Option>
                                        <Option value='钢坯'>钢坯</Option>
                                    </Select>)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='炉批号'>
                                {getFieldDecorator('batchNo',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Input style={{ width: '150px' }} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='用户'>
                                {getFieldDecorator('user',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Input style={{ width: '150px' }} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='日期'>
                                {getFieldDecorator('date', { rules: [{ required: false, message: '' }], })
                                    (<RangePicker style={{ width: '240px' }} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={this.search}
                                icon="search"
                                style={{ marginLeft: 8 }}
                            >
                                搜索
                            </Button>
                            <Button style={{ marginLeft: 16 }} onClick={this.clear}>
                                重置
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(ProductModifyRecordSearch);