import { request } from '../utils/http';

export const billetList = (param) => {
    return request(
        '/billet/list',
        'POST',
        param
    );
};

export const getBilletById = (billetId) => {
    return request(
        '/billet/getBillet',
        'POST',
        { billetId }
    );
};

export const updateById = (param) => {
    return request(
        '/billet/update',
        'POST',
        param
    );
};

export const insertBillet = (param) => {
    return request(
        '/billet/insert',
        'POST',
        param
    );
};

export const isFurnaceNoExist = (furnaceNo) => {
    return request(
        '/billet/isFurnaceNoExist',
        'POST',
        { furnaceNo }
    );
};

export const delBillet = (billetId) => {
    return request(
        '/billet/del',
        'POST',
        { billetId }
    );
};

export const deliverBillet = (billetId) => {
    return request(
        '/billet/deliver',
        'POST',
        { billetId }
    );
};

export const rejectBillet = (billetId) => {
    return request(
        '/billet/reject',
        'POST',
        { billetId }
    );
};

export const exportBilletList = (param) => {
    return request(
        '/billet/exportBilletList',
        'post',
        param
    );
};