import React, { Component, Fragment } from 'react';
import userState from '../../store/index';


export default class AuthLayer extends Component{

    render() {
        const path = window.location.pathname;
        const menuAuth = userState.getMenuAut(path);
        return (
            <Fragment>
                {(menuAuth && menuAuth.readonly !== '1') && (
                    this.props.children
                ) }
            </Fragment>
        );
    }
}