import React, { Component } from 'react';
import styles from './index.module.scss';
import { Input, DatePicker, Button, Form } from 'antd';

class ClientsSearch extends Component {


    search = () => {
        let { submit } = this.props;
        let values = (this.props.form.getFieldsValue());
        let { customerName, phone} = values;
        submit && submit(customerName || '', phone || '');
    }

    clear = () => {
        let { submit } = this.props;
        this.props.form.resetFields();
        submit && submit('', '');
    }

    render() {
        let { getFieldDecorator } = this.props.form;
        return (
            <div className={styles.searchBox}>
                <Form layout='inline'>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Form.Item label='收货单位'>
                                {getFieldDecorator('customerName',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Input style={{ width: '240px' }} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Form.Item label='手机号'>
                                {getFieldDecorator('phone',
                                    {
                                        rules: [{ required: false, message: '' }],
                                    })
                                    (<Input style={{ width: '180px' }} maxLength={20} allowClear />)}
                            </Form.Item>
                        </div>
                        <div className={styles.col}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={this.search}
                                icon="search"
                                style={{ marginLeft: 8 }}
                            >
                                搜索
                            </Button>
                            <Button style={{ marginLeft: 16 }} onClick={this.clear}>
                                重置
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(ClientsSearch);