import React, { Component, Fragment } from 'react';
import { InputNumber, Button, Spin, Empty, message, Select, Modal } from 'antd';
import { observer } from 'mobx-react';
import styles from './index.module.scss';
import { observable } from 'mobx';
import { insertMech, getByBatchNo, getUnDeliverBatch } from '../../../api/mech';

const { Option } = Select;

@observer
class AddDeformed extends Component {

    @observable
    show = false;

    @observable
    loading = false;

    @observable
    submitting = false;

    @observable
    batchNo = '';

    @observable
    list = null;

    @observable
    unDeliverBatch = [];


    onClose = () => {
        this.batchNo = '';
        this.list = null;
        let { drawerOnClose } = this.props;
        drawerOnClose && drawerOnClose(false);
    }

    getUnDeliverBatch = async () => {
        try {
            this.loading = true;
            let resp = await getUnDeliverBatch();
            let { data } = resp;
            if (data.code === 0) {
                this.unDeliverBatch = data.data;
            }
            else {
                this.list = [];
                message.warn(data.msg);
            }
        }
        finally {
            this.loading = false;
        }
    }

    batchNoChange = async (e) => {
        this.batchNo = e.trim();
        if (this.batchNo && this.batchNo !== '') {
            try {
                this.loading = true;
                let resp = await getByBatchNo(this.batchNo, '螺纹钢');
                let { data } = resp;
                if (data.code === 30000) {
                    this.list = data.data;
                }
                else {
                    this.list = [];
                    message.warn(data.msg);
                }
            }
            finally {
                this.loading = false;
            }
        }
    }


    changeRel = (value, index) => {
        this.list[index].rel = value;
    }

    changeRm = (value, index) => {
        this.list[index].rm = value;
    }

    changeEl = (value, index) => {
        this.list[index].el = value;
    }

    changeL = (value, index) => {
        this.list[index].l = value;
    }

    changeAgt = (value, index) => {
        this.list[index].agt = value;
    }

    changeColdBending = (value, index) => {
        this.list[index].coldBending = value;
    }

    changeBackBending = (value, index) => {
        this.list[index].backBending = value;
    }

    changeMetallographic = (value, index) => {
        this.list[index].metallographic = value;
    }

    changeWeight = (value, index) => {
        this.list[index].weightDeviation = value;
    }

    changeOutLooking = (value, index) => {
        this.list[index].outLooking = value;
    }


    handleSubmit = async () => {
        if (!this.batchNo || this.batchNo === '') {
            message.error(`请输入轧钢批号`);
            return;
        }
        for (let index in this.list) {
            let item = this.list[index];
            item.prodName = '螺纹钢';
            let rowNum = +index + 1;
            if (!item.rel || (item.rel + '').trim() === '') {
                message.error(`第${rowNum}行：ReL为空`);
                return;
            }
            if (!item.rm || (item.rm + '').trim() === '') {
                message.error(`第${rowNum}行：Rm为空`);
                return;
            }
            if (!item.el || (item.el + '').trim() === '') {
                message.error(`第${rowNum}行：Rm/R°为空`);
                return;
            }
            if (!item.l || (item.l + '').trim() === '') {
                message.error(`第${rowNum}行：ReL/Re为空`);
                return;
            }
            if (!item.agt || (item.agt + '').trim() === '') {
                message.error(`第${rowNum}行：Agt为空`);
                return;
            }
        }
        try {
            this.submitting = true;
            let resp = await insertMech(this.list);
            let { data } = resp;
            if (data.code === 0) {
                message.success('添加成功');
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.submitting = false;
            this.batchNo = '';
            this.list = null;
            let { drawerOnClose } = this.props;
            drawerOnClose && drawerOnClose(false);
        }
    };

    render() {
        let { show } = this.props;
        return (
            <div>
                <Modal
                    title="新增螺纹钢实验记录"
                    width={1300}
                    onCancel={this.onClose}
                    visible={show}
                    footer={null}
                >
                    <div className={styles.box}>
                        <div className={styles.row}>
                            <div className={styles.required}>*</div>
                            <div className={styles.label}>轧钢批号:</div>
                            <div className={styles.value}>
                                <Select
                                    style={{ width: 180 }}
                                    autoFocus={true}
                                    placeholder='请输入轧钢批号'
                                    value={this.batchNo}
                                    onChange={this.batchNoChange}
                                    showSearch
                                    onFocus={this.getUnDeliverBatch}
                                    loading={this.loading}
                                >
                                    {(this.unDeliverBatch && this.unDeliverBatch.length > 0)
                                        && (this.unDeliverBatch.map((batchNo, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    value={batchNo}
                                                >
                                                    {batchNo}
                                                </Option>);
                                        }))}
                                </Select>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.th}>
                                <div className={styles.col} style={{
                                    width: '120px',
                                    textAlign: 'left',
                                    paddingLeft: '20px'
                                }}>炉号</div>
                                <div className={styles.col} style={{
                                    width: '120px',
                                    textAlign: 'left',
                                    paddingLeft: '20px'
                                }}>牌号</div>
                                <div className={styles.col} style={{ width: '90px' }}>
                                    <div>R<sub>eL</sub></div>
                                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>(MPa)</div>
                                </div>
                                <div className={styles.col} style={{ width: '90px' }}>
                                    <div>R<sub>m</sub></div>
                                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>(MPa)</div>
                                </div>
                                <div className={styles.col} style={{ width: '90px' }}>
                                    <div>R<sub>m</sub>/R°</div>
                                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>eL</div>
                                </div>
                                <div className={styles.col} style={{ width: '90px' }}>
                                    <div>R<sub>eL</sub>/R<sub>e</sub></div>
                                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>L</div>
                                </div>
                                <div className={styles.col} style={{ width: '90px' }}>
                                    <div>A<sub>gt</sub></div>
                                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>(%)</div>
                                </div>
                                <div className={styles.col} style={{ width: '90px' }}>冷弯试验</div>
                                <div className={styles.col} style={{ width: '90px' }}>反弯试验</div>
                                <div className={styles.col} style={{ width: '90px' }}>金相检测</div>
                                <div className={styles.col} style={{ width: '90px' }}>重量偏差</div>
                                <div className={styles.col} style={{ width: '90px' }} >
                                    <div>外形尺寸</div>
                                    <div>表面质量</div>
                                </div>
                            </div>
                            {(this.list && this.list.length > 0) && (
                                <Fragment>
                                    {this.list.map((item, index) => {
                                        this.list[index].coldBending = '合格';
                                        this.list[index].backBending = '合格';
                                        return (
                                            <div key={index} className={styles.tr}>
                                                <div className={styles.col} style={{
                                                    width: '120px',
                                                    textAlign: 'left',
                                                    paddingLeft: '20px'
                                                }}>{item.furnaceNo}</div>
                                                <div className={styles.col} style={{
                                                    width: '120px',
                                                    textAlign: 'left',
                                                    paddingLeft: '20px'
                                                }}>{item.grade}</div>
                                                <div className={styles.col} style={{ width: '90px' }}>
                                                    <InputNumber
                                                        style={{ width: '80px', textAlign: 'center' }}
                                                        precision={0}
                                                        step={1}
                                                        min={0}
                                                        max={1000}
                                                        value={this.list[index].rel}
                                                        onChange={(value) => { this.changeRel(value, index) }}
                                                    />
                                                </div>
                                                <div className={styles.col} style={{ width: '90px' }}>
                                                    <InputNumber
                                                        style={{ width: '80px', textAlign: 'center' }}
                                                        precision={0}
                                                        step={1}
                                                        min={0}
                                                        max={1000}
                                                        value={this.list[index].rm}
                                                        onChange={(value) => { this.changeRm(value, index) }}
                                                    />
                                                </div>
                                                <div className={styles.col} style={{ width: '90px' }}>
                                                    <InputNumber
                                                        style={{ width: '80px', textAlign: 'center' }}
                                                        precision={2}
                                                        step={0.01}
                                                        min={0}
                                                        max={1000}
                                                        value={this.list[index].el}
                                                        onChange={(value) => { this.changeEl(value, index) }}
                                                    />
                                                </div>
                                                <div className={styles.col} style={{ width: '90px' }}>
                                                    <InputNumber
                                                        style={{ width: '80px', textAlign: 'center' }}
                                                        precision={2}
                                                        step={0.01}
                                                        min={0}
                                                        max={1000}
                                                        value={this.list[index].l}
                                                        onChange={(value) => { this.changeL(value, index) }}
                                                    />
                                                </div>
                                                <div className={styles.col} style={{ width: '90px' }}>
                                                    <InputNumber
                                                        style={{ width: '80px', textAlign: 'center' }}
                                                        precision={1}
                                                        step={0.1}
                                                        min={0}
                                                        max={100}
                                                        value={this.list[index].agt}
                                                        onChange={(value) => { this.changeAgt(value, index) }}
                                                    />
                                                </div>
                                                <div className={styles.col} style={{ width: '90px' }}>
                                                    <Select
                                                        style={{ width: '80px' }}
                                                        defaultValue='合格'
                                                        onChange={(value) => { this.changeColdBending(value, index) }}
                                                    >
                                                        <Option value="合格">合格</Option>
                                                        <Option value="微裂纹">微裂纹</Option>
                                                        <Option value="裂纹">裂纹</Option>
                                                        <Option value="裂缝">裂缝</Option>
                                                        <Option value="裂断">裂断</Option>
                                                    </Select>
                                                </div>
                                                <div className={styles.col} style={{ width: '90px' }}>
                                                    <Select
                                                        style={{ width: '80px' }}
                                                        defaultValue='合格'
                                                        onChange={(value) => { this.changeBackBending(value, index) }}
                                                    >
                                                        <Option value="合格">合格</Option>
                                                        <Option value="微裂纹">微裂纹</Option>
                                                        <Option value="裂纹">裂纹</Option>
                                                        <Option value="裂缝">裂缝</Option>
                                                        <Option value="裂断">裂断</Option>
                                                    </Select>
                                                </div>
                                                <div className={styles.col} style={{ width: '90px' }}>
                                                    <Select
                                                        style={{ width: '80px' }}
                                                        defaultValue='合格'
                                                        onChange={(value) => { this.changeMetallographic(value, index) }}
                                                    >
                                                        <Option value="合格">合格</Option>
                                                        <Option value="不合格">不合格</Option>
                                                    </Select>
                                                </div>
                                                <div className={styles.col} style={{ width: '90px' }}>
                                                    <Select
                                                        style={{ width: '80px' }}
                                                        defaultValue='合格'
                                                        onChange={(value) => { this.changeWeight(value, index) }}
                                                    >
                                                        <Option value="合格">合格</Option>
                                                        <Option value="不合格">不合格</Option>
                                                    </Select>
                                                </div>
                                                <div className={styles.col} style={{ width: '90px' }}>
                                                    <Select
                                                        style={{ width: '80px' }}
                                                        defaultValue='合格'
                                                        onChange={(value) => { this.changeOutLooking(value, index) }}
                                                    >
                                                        <Option value="合格">合格</Option>
                                                        <Option value="不合格">不合格</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Fragment>
                            )}
                            <Spin spinning={this.loading} style={{ width: '100%' }}>
                                {this.batchNo === '' && (
                                    <Empty
                                        style={{ paddingTop: '30px' }}
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description='输入轧钢批号自动加载钢坯数据'
                                    />
                                )}
                                {(this.list && this.list.length === 0 && this.batchNo !== '') && (
                                    <Empty
                                        style={{ paddingTop: '30px' }}
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description='无符合要求的轧钢批号'
                                    />
                                )}
                            </Spin>
                        </div>
                        <div className={styles.btnBar}>
                            <Button
                                type='primary'
                                style={{ width: '80px' }}
                                onClick={this.handleSubmit}
                                loading={this.submitting}
                            >
                                确定
                        </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default AddDeformed;