import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Form, InputNumber, Select, DatePicker, Modal, message } from 'antd';
import styles from './index.module.scss';
import moment from 'moment';
import { productExist, getUnDeliverMech } from '../../../api/product';

const { Option } = Select;

const boundOptions = [
    { label: '⌀14', value: '14' },
    { label: '⌀16', value: '16' },
    { label: '⌀18', value: '18' },
    { label: '⌀20', value: '20' },
    { label: '⌀22', value: '22' },
    { label: '⌀25', value: '25' },
    { label: '⌀28', value: '28' },
    { label: '⌀30', value: '30' },
    { label: '⌀32', value: '32' },
    { label: '⌀34', value: '34' },
    { label: '⌀35', value: '35' },
    { label: '⌀36', value: '36' },
    { label: '⌀38', value: '38' },
    { label: '⌀40', value: '40' },
    { label: '⌀42', value: '42' },
    { label: '⌀45', value: '45' },
    { label: '⌀48', value: '48' },
    { label: '⌀50', value: '50' },
    { label: '⌀55', value: '55' },
    { label: '⌀56', value: '56' },
    { label: '⌀60', value: '60' },
];

const deformedOptions = [
    { label: '⌀12', value: '12' },
    { label: '⌀14', value: '14' },
    { label: '⌀16', value: '16' },
    { label: '⌀18', value: '18' },
    { label: '⌀20', value: '20' },
    { label: '⌀22', value: '22' },
    { label: '⌀25', value: '25' },
    { label: '⌀28', value: '28' },
    { label: '⌀30', value: '30' },
    { label: '⌀32', value: '32' },
    { label: '⌀34', value: '34' },
    { label: '⌀35', value: '35' },
    { label: '⌀36', value: '36' },
    { label: '⌀38', value: '38' },
    { label: '⌀40', value: '40' },
    { label: '⌀42', value: '42' },
];

@observer
class AddProduct extends Component {

    @observable
    prodName = '圆钢';

    @observable
    show = false;

    @observable
    loading = false;

    @observable
    submitting = false;

    @observable
    batchNo = '';

    @observable
    prodTime = moment();

    @observable
    shift = '';

    @observable
    shiftList = [];

    @observable
    shiftListLoading = false;

    @observable
    batchNoExist = false;

    @observable
    tipsShow = false;

    @observable
    tips = '';

    @observable
    unDeliverMech = [];


    componentDidMount() {
        this.prodName = this.props.prodName;
        this.loading = this.props.loading;
        this.shiftList = this.props.shiftList;
        if (this.shiftList && this.shiftList.length > 0) {
            this.shift = this.shiftList[0];
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.prodName !== nextProps.prodName) {
            this.prodName = nextProps.prodName;
        }
        if (this.shiftList !== nextProps.shiftList) {
            this.shiftList = nextProps.shiftList;
            if (this.shiftList && this.shiftList.length > 0) {
                this.shift = this.shiftList[0];
            }
        }
        if (this.loading !== nextProps.loading) {
            this.loading = nextProps.loading;
        }
    }

    getUnDeliverMech = async () => {
        try {
            this.loading = true;
            let resp = await getUnDeliverMech(this.prodName);
            let { data } = resp;
            if (data.code === 0) {
                this.unDeliverMech = data.data;
            }
            else {
                this.list = [];
                message.warn(data.msg);
            }
        }
        finally {
            this.loading = false;
        }
    }

    queryBatchNo = async () => {
        let { batchNo, prodName } = this;
        if (batchNo && batchNo !== '') {
            try {
                this.loading = true;
                let resp = await productExist(batchNo, prodName);
                let { data } = resp;
                if (data.code === 30001) {
                    this.batchNoExist = true;
                    this.tips = '';
                }
                if (data.code === 40001) {
                    this.batchNoExist = false;
                    this.tips = `该批号已经添加到成品台账`;
                }
                if (data.code === 30002) {
                    this.batchNoExist = false;
                    this.tips = `该批号对应的产品种类不是【${this.prodName}】`;
                }
                if (data.code === 30000) {
                    this.batchNoExist = false;
                    this.tips = `该批号该批号尚未录入物理试验台账`;
                }
            }
            finally {
                this.loading = false;
            }
        }
    }

    onClose = () => {
        this.batchNo = '';
        this.batchNoExist = false;
        let { drawerOnClose } = this.props;
        drawerOnClose && drawerOnClose();
    }

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.batchNo = this.batchNo;
                values.prodTime = moment(this.prodTime).format("YYYY-MM-DD");
                values.shift = this.shift;
                values.prodName = this.prodName;
                let { insertProduct } = this.props;
                insertProduct && insertProduct(values);
                this.props.form.resetFields();
                this.batchNo = '';
                this.batchNoExist = false;
            }
        });
    };

    onBatchNoChange = (e) => {
        this.batchNo = e;
        this.queryBatchNo();
    }

    onProdTimeChange = (date, dateString) => {
        this.prodTime = date
    }

    onShiftChange = (value) => {
        this.shift = value;
    }

    onTipsShow = () => {
        this.tipsShow = !this.batchNoExist && this.batchNo !== '';
    }

    onTipsHidden = () => {
        this.tipsShow = false;
    }

    render() {
        let { show } = this.props;
        let { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title={`新增${this.prodName}成品记录`}
                onCancel={this.onClose}
                visible={show}
                width={1250}
                footer={null}
            >
                <div className={styles.addContent}>
                    <div className={styles.basicRow}>
                        <div className={styles.required}>*</div>
                        <div className={styles.basicLabel}>轧钢批号:</div>
                        <div className={styles.basicValue}>
                            <Select
                                style={{ width: 210 }}
                                autoFocus={true}
                                value={this.batchNo}
                                onChange={this.onBatchNoChange}
                                placeholder='请输入轧钢批号'
                                onFocus={() => {
                                    this.getUnDeliverMech();
                                    this.onTipsHidden();
                                }}
                                onBlur={this.onTipsShow}
                                showSearch
                                loading={this.loading}
                            >
                                {(this.unDeliverMech && this.unDeliverMech.length > 0)
                                    && (this.unDeliverMech.map((batchNo, index) => {
                                        return (
                                            <Option
                                                key={index}
                                                value={batchNo}
                                            >
                                                {batchNo}
                                            </Option>);
                                    }))}
                            </Select>
                            {this.tipsShow && (
                                <div className={styles.tips}>{this.tips}</div>
                            )}
                        </div>
                        <div className={styles.required}>*</div>
                        <div className={styles.basicLabel}>生产日期:</div>
                        <div className={styles.basicValue}>
                            <DatePicker
                                style={{ width: 210 }}
                                placeholder="请选择日期"
                                onChange={this.onProdTimeChange}
                                value={this.prodTime}
                                disabled={!this.batchNoExist}
                                allowClear={false}
                            />
                        </div>

                        <div className={styles.required}>*</div>
                        <div className={styles.basicLabel}>班次:</div>
                        <div className={styles.basicValue}>
                            <Select
                                value={this.shift}
                                style={{ width: 210 }}
                                loading={this.shiftListLoading}
                                disabled={!this.batchNoExist}
                                onChange={this.onShiftChange}
                            >
                                {(this.shiftList && this.shiftList.length > 0) && (
                                    this.shiftList.map((item, index) => {
                                        return (
                                            <Option key={index} value={item}>{item}</Option>
                                        );
                                    })
                                )}
                            </Select>
                        </div>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        <div className={styles.row}>
                            <div className={styles.raw}>
                                <div className={styles.subTitle}>投料量</div>
                                <div className={styles.subRow}>
                                    <div className={styles.col}>
                                        <Form.Item label='支数'>
                                            {getFieldDecorator('rawNumber',
                                                {
                                                    rules: [{ required: false, message: '请填写' }],
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={1}
                                                    precision={0}
                                                />)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='重量(t)'>
                                            {getFieldDecorator('rawWeight',
                                                {
                                                    rules: [{ required: false, message: '请填写' }],
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={0.001}
                                                    precision={3}
                                                />)}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.prod}>
                                <div className={styles.subTitle}>成品</div>
                                <div className={styles.subRow}>
                                    <div className={styles.col}>
                                        <Form.Item label='规格'>
                                            {getFieldDecorator('spec',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                })
                                                (<Select
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    allowClear
                                                    showSearch
                                                >
                                                    {this.prodName === '圆钢' && (
                                                        boundOptions.map((spec, index) => {
                                                            return (
                                                                <Option key={index} value={spec.value}>{spec.label}</Option>
                                                            );
                                                        })
                                                    )}
                                                    {this.prodName === '螺纹钢' && (
                                                        deformedOptions.map((spec, index) => {
                                                            return (
                                                                <Option key={index} value={spec.value}>{spec.label}</Option>
                                                            );
                                                        })
                                                    )}
                                                </Select>)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='长度(m)'>
                                            {getFieldDecorator('length',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: this.prodName === '圆钢' ? 9 : 12
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={100}
                                                    step={1}
                                                    precision={2}
                                                />)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='捆数'>
                                            {getFieldDecorator('prodNumber',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={1}
                                                    precision={0}
                                                />)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='重量'>
                                            {getFieldDecorator('prodWeight',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={0.001}
                                                    precision={3}
                                                />)}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.prod}>
                                <div className={styles.subTitle}></div>
                                <div className={styles.subRow}>
                                    <div className={styles.col}>
                                        <Form.Item label='检验废(t)'>
                                            {getFieldDecorator('unqualified',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 0
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={0.001}
                                                    precision={3}
                                                />)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='待处理(t)'>
                                            {getFieldDecorator('pendingDisposal',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 0
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={0.001}
                                                    precision={3}
                                                />)}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.prodRight}>
                                <div className={styles.subTitle}>乱尺</div>
                                <div className={styles.subRow}>
                                    <div className={styles.col}>
                                        <Form.Item label='重量(t)'>
                                            {getFieldDecorator('disorderlyFeetWeight',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 0
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={0.001}
                                                    precision={3}
                                                />)}
                                        </Form.Item>
                                    </div>
                                    <div className={styles.col}>
                                        <Form.Item label='捆数'>
                                            {getFieldDecorator('disorderlyFeetNumber',
                                                {
                                                    rules: [{ required: true, message: '请填写' }],
                                                    initialValue: 0
                                                })
                                                (<InputNumber
                                                    disabled={!this.batchNoExist}
                                                    style={{ width: '100px' }}
                                                    max={1000}
                                                    step={1}
                                                    precision={0}
                                                />)}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.btnRow}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: 80 }}
                                disabled={!this.batchNoExist || this.loading}
                                loading={this.loading}
                            >
                                确定
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        );
    }

}

export default Form.create()(AddProduct);;