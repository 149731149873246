import { ConfigProvider, message } from 'antd';
import 'antd/dist/antd.css';
import zhCN from 'antd/es/locale/zh_CN';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { isLogin } from './api/user';
import './App.css';
import TopMenu from './component/menu';
import StatusBar from './component/status';
import Batch from './views/batch';
import Billet from './views/billet';
import ComponentAnalysis from './views/componentAnalysis';
import Customer from './views/customer';
import CustomerWarranty from './views/customer/warranty';
import Login from './views/login';
import Mech from './views/mech';
import Product from './views/product/Index';
import Stock from './views/stock';
import UploadWarrantyImage from './views/stock/uploadWarrantyImage';
import Warranty from './views/stock/warranty';
import Role from './views/role';
import UserManage from './views/user';
import SysConfig from './views/sys';
import Grade from './views/grade';
import ProductModifyRecord from './views/productModifyRecord';
import Clients from './views/clients';

moment.locale('en');

const CURRENT_PATH = window.location.pathname;

@observer
class App extends React.Component {

    @observable
    isLogin = false;

    timer = null;

    componentDidMount() {
        this.isUserLogin();
        setTimeout(this.iTimer, 1000);
    }

    componentWillUnmount() {
        this.timer = null;
    }

    iTimer = () => {
        this.timer = setInterval(() => {
            this.isUserLogin();
        }, 5000);
    }

    isUserLogin = async () => {
        try {
            const response = await isLogin();
            if (response) {
                const { data } = response;
                if (data.data) {
                    this.isLogin = true;
                } else {
                    this.isLogin = false;
                }
                if (!data.data &&
                    CURRENT_PATH !== '/login' &&
                    CURRENT_PATH.split('/')[1] !== 'customer' &&
                    CURRENT_PATH.split('/')[1] !== 'customerWarranty') {
                    window.location.href = ('/login');
                }
            }
        }
        catch{
            message.error('网络连接中断');
        }
    }

    render() {
        let { isLogin } = this;
        return (
            <ConfigProvider locale={zhCN}>
                <Router >
                    <Route path="/login" component={Login} />
                    <Route path="/customer" component={Customer} />
                    <Route path="/customerWarranty/:stockId" component={CustomerWarranty} />
                    <Route path="/uploadWarrantyImage/:stockId" component={UploadWarrantyImage} />
                    {(CURRENT_PATH.split('/')[1] !== 'customer' &&
                        CURRENT_PATH.split('/')[1] !== 'customerWarranty' &&
                        CURRENT_PATH.split('/')[1] !== 'uploadWarrantyImage') && (
                            <Fragment>
                                <div>{isLogin && < StatusBar />}</div>
                                <div className='content-page'>
                                    <div className='content-page-left-menu'>
                                        {isLogin && <TopMenu />}
                                    </div>
                                    <div className='content-page-main'>
                                        <Route path="/componentAnalysis" component={ComponentAnalysis} />
                                        <Route path="/billetAcceptance" component={Billet} />
                                        <Route path="/billetBatch" component={Batch} />
                                        <Route path="/physicalTest" component={Mech} />
                                        <Route path="/endProduct" component={Product} />
                                        <Route path="/outGoing" component={Stock} />
                                        <Route path="/roleAdmin" component={Role} />
                                        <Route path="/userAdmin" component={UserManage} />
                                        <Route path="/warranty/:stockId" component={Warranty} />
                                        <Route path="/sysConfig" component={SysConfig} />
                                        <Route path="/gradeConfig" component={Grade} />
                                        <Route path="/productModifyRecord" component={ProductModifyRecord} />
                                        <Route path="/clientInfo" component={Clients} />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                </Router>
            </ConfigProvider>
        );
    }
}

export default App;
