import { request } from '../utils/http';

export const insertMech = (param) => {
    return request(
        '/mech/insert',
        'POST',
        param
    );
};

export const getByBatchNo = (batchNo, prodName) => {
    return request(
        '/mech/getByBatchNo',
        'POST',
        {batchNo, prodName}
    );
};

export const mechList = (param) => {
    return request(
        '/mech/list',
        'POST',
        param
    );
};

export const delMech = (batchNo) => {
    return request(
        '/mech/del',
        'POST',
        {batchNo}
    );
};

export const batcNoExist = (batchNo, prodName) => {
    return request(
        '/mech/exist',
        'POST',
        {batchNo, prodName}
    );
};

export const mechDetail = (batchNo) => {
    return request(
        '/mech/detail',
        'POST',
        {batchNo}
    );
};

export const updateMech = (param) => {
    return request(
        '/mech/update',
        'POST',
        param
    );
};

export const getUnDeliverBatch = () => {
    return request(
        '/mech/getUnDeliverBatch',
        'GET',
    );
};