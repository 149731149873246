import { request } from '../utils/http';

export const getRoleList = (param) => {
    return request(
        '/role/roleList',
        'POST',
        param
    );
};

export const insertRole = (param) => {
    return request(
        '/role/insertRole',
        'POST',
        param
    );
};

export const getRoleById = (param) => {
    return request(
        '/role/getRoleById',
        'POST',
        param
    );
};

export const updateRole = (param) => {
    return request(
        '/role/updateRole',
        'POST',
        param
    );
};

export const getAuthByRole = (param) => {
    return request(
        '/role/getAuthByRole',
        'POST',
        param
    );
};

export const updateAuthByRole = (param) => {
    return request(
        '/role/updateAuthByRole',
        'POST',
        param
    );
};

export const deleteRole = (param) => {
    return request(
        '/role/deleteRole',
        'POST',
        param
    );
};

export const allRole = () => {
    return request(
        '/role/allRole',
        'POST',
    );
};