import { observable, action } from 'mobx'

class UserState {
    // 装饰器@
    @observable menuList = [];

    @action
    setMenuList(menuList) {
        this.menuList = menuList;
    }

    @action
    getMenuAut(resource) {
        let menuAuth = null
        this.menuList.forEach(m => {
            if (`/${m.resource}` === resource) {
                menuAuth = m;
            }
        })
        return menuAuth;
    }

}
const userState = new UserState()

export default userState;