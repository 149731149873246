// @ts-nocheck
import React, { Component, Fragment } from 'react';
import styles from './index.module.scss';
import { Spin, Icon, message } from 'antd';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { warrantySheet } from '../../../api/stock';


@observer
class CustomerWarranty extends Component {

    @observable loading = false;
    @observable warrantyList = [];
    currentSheet;

    componentDidMount() {
        this.fetchWarrantySheet();
    }

    fetchWarrantySheet = async () => {
        try {
            this.warrantyLoading = true;
            let response = await warrantySheet(this.props.match.params.stockId);
            let { data } = response;
            if (data.code === 0) {
                this.warrantyList = data.data;
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.warrantyLoading = false;
        }
    }


    render() {
        let { warrantyList, loading } = this;
        if (warrantyList && warrantyList.length > 0) {
            this.currentSheet = warrantyList[0].warrantySheetNo;
        }
        return (
            <Spin spinning={loading}>
                {this.warrantyList.length > 0 && (
                    <Fragment>
                        <div className={styles.top}>
                            <div
                                className={styles.btn}
                                onClick={() => { this.props.history.goBack(); }}
                                style={{ color: '#ffffff', fontWeight: 'bold' }}
                            >
                                <Icon type="left" /> 返回
                            </div>
                        </div>
                        <div className={styles.tips}>
                            <Icon type="download" />
                            长按图片保存到本地下载质保单
                        </div>
                        {this.warrantyList && (
                            this.warrantyList.map((w, i) => {
                                return (
                                    <div style={{ marginTop: '2rem' }}>
                                        <img
                                            src={`/qc-image/${this.props.match.params.stockId}/兴华钢铁质量保证书${i}.png?t=${(+new Date())}`}
                                            width={document.body.clientWidth}
                                            height='auto'
                                            alt='质保单'
                                        />
                                    </div>
                                );
                            })
                        )}

                        <div className={styles.footer}></div>
                    </Fragment>
                )}
            </Spin>
        );
    }
}

export default CustomerWarranty;