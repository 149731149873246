import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Tabs, Button, message, Modal, Upload } from 'antd';
import styles from './index.module.scss';
import MechSearch from './search';
import Deformed from './deformedList';
import AddDeformed from './deformedAdd';
import { mechList, delMech } from '../../api/mech';
import ModifyDeformed from './deformedModify';
import AddBound from './boundAdd';
import Bound from './boundList';
import ModifyBound from './boundModify';
import { getCookie, getEnv } from '../../utils/http';
import axios from 'axios';
import AuthLayer from '../../component/authLayer';


const { TabPane } = Tabs;

@observer
class Mech extends Component {

    @observable
    prodName = '圆钢';

    @observable
    add = false;

    @observable
    pageNum = 1;

    @observable
    pageSize = 10;

    @observable
    total = 0;

    param = {};

    @observable
    loading = false;

    @observable
    list = [];

    @observable
    modifyShow = false;

    @observable
    currentBatchNo = '';

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        let { pageNum, pageSize, param } = this;
        param.prodName = this.prodName;
        try {
            this.loading = true;
            let resp = await mechList({ pageNum, pageSize, ...param });
            let { data } = resp
            if (data.code === 0) {
                this.list = data.data.list;
                this.pageNum = data.data.pageNum;
                this.pageSize = data.data.pageSize;
                this.total = data.data.total;
            }
            else {
                message.error('查询出错');
            }
        }
        finally {
            this.loading = false;
        }
    }

    del = async (batchNo) => {
        try {
            this.loading = true;
            let resp = await delMech(batchNo);
            let { data } = resp
            if (data.code === 0) {
                message.success('删除成功');
                this.fetch();
            }
            else {
                message.error('操作失败');
            }
        }
        finally {
            this.loading = false;
        }
    }

    tabChange = (key) => {
        this.add = false;
        if (key === "1") {
            this.prodName = '圆钢';
        }
        if (key === "2") {
            this.prodName = '螺纹钢';
        }
        this.fetch();
    }

    addDrawerClose = (isClose) => {
        this.add = isClose;
        this.fetch();
    }

    addDrawerOpen = () => {
        this.add = true;
    }

    onShowSizeChange = (current, size) => {
        this.pageNum = current;
        this.pageSize = size;
        this.fetch();
    }

    onTableChange = (current) => {
        this.pageNum = current;
        this.fetch();
    }

    submit = (param) => {
        this.param = param;
        this.fetch();
    }

    modify = (batchNo) => {
        this.modifyShow = true;
        this.currentBatchNo = batchNo;
    }

    modifyCancel = () => {
        this.currentBatchNo = '';
        this.modifyShow = false;
        this.fetch();
    }

    export = async () => {
        this.loading = true;
        let { param, prodName } = this;
        param.prodName = this.prodName;
        axios({
            method: "POST",
            url: getEnv() === 'DEV' ? `/mech/exportMech` : '/api/mech/exportMech',
            headers: {
                'Content-Type': 'application/json'
            },
            data: { ...param },
            responseType: 'blob'
        }).then((res) => {
            let blob = res.data;
            let url = window.URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = url;
            el.download = `${prodName}物理实验台账.xlsx`;
            el.hidden = true;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            this.loading = false;
        });
    }

    exportTemplate = async () => {
        this.loading = true;
        let { param, prodName } = this;
        param.prodName = this.prodName;
        axios({
            method: "POST",
            url: getEnv() === 'DEV' ? `/mech/exportTemplate` : '/api/mech/exportTemplate',
            headers: {
                'Content-Type': 'application/json'
            },
            data: { ...param },
            responseType: 'blob'
        }).then((res) => {
            let blob = res.data;
            let url = window.URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = url;
            el.download = `${prodName}物理实验台账模板.xlsx`;
            el.hidden = true;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            this.loading = false;
        });
    }

    getUploadProps = () => {
        let that = this;
        const path = this.prodName === '圆钢' ? 'importMechBound' : 'importMechDeformed';
        return (
            {
                name: 'file',
                action: getEnv() === 'DEV' ? `/mech/${path}` : `/api/mech/${path}`,
                headers: {
                    authorization: `Bearer ${getCookie('token')}`,
                },
                onChange(info) {
                    if (info.file.status === 'uploading') {
                        that.loading = true;
                    }
                    if (info.file.status === 'done') {
                        message.success(`${info.file.response.msg}`)
                        that.fetch();
                        that.loading = false;
                    } else if (info.file.status === 'error') {
                        that.loading = false;
                        message.error(`${info.file.name} 上传失败 ${info.file.response.msg}`);
                    }
                },
            }
        );
    }

    render() {
        let uploadProps = this.getUploadProps();
        return (
            <Fragment>
                <Tabs defaultActiveKey="1" onChange={this.tabChange}>
                    <TabPane tab="圆钢" key="1" />
                    <TabPane tab="螺纹钢" key="2" />
                </Tabs>
                <MechSearch submit={this.submit} />
                <div className={styles.tools}>
                    <AuthLayer>
                        <Button type='link' onClick={this.addDrawerOpen}>新增</Button>
                        <Button type='link' onClick={this.exportTemplate}>导出数据模板</Button>
                        <Upload
                            {...uploadProps}
                            multiple={false}
                            accept={'xlsx'}
                            showUploadList={false}
                        >
                            <Button type='link'>导入实验数据</Button>
                        </Upload>
                    </AuthLayer>
                    <Button type='link' onClick={this.export}>导出数据</Button>
                </div>
                <AddDeformed
                    show={this.add && this.prodName === '螺纹钢'}
                    drawerOnClose={this.addDrawerClose}
                />
                <AddBound
                    show={this.add && this.prodName === '圆钢'}
                    drawerOnClose={this.addDrawerClose}
                />
                <Modal
                    title='修改物理试验记录'
                    width={this.prodName === '螺纹钢' ? 1100 : 850}
                    visible={this.modifyShow}
                    onCancel={() => this.modifyCancel()}
                    footer={null}
                >
                    {this.prodName === '螺纹钢' &&
                        <ModifyDeformed
                            batchNo={this.currentBatchNo}
                            modifyCancel={this.modifyCancel}
                        />}
                    {this.prodName === '圆钢' &&
                        <ModifyBound
                            batchNo={this.currentBatchNo}
                            modifyCancel={this.modifyCancel}
                        />}
                </Modal>
                {this.prodName === '螺纹钢' && (
                    <Deformed
                        list={this.list}
                        pageNum={this.pageNum}
                        pageSize={this.pageSize}
                        total={this.total}
                        onShowSizeChange={this.onShowSizeChange}
                        onTableChange={this.onTableChange}
                        del={this.del}
                        modify={this.modify}
                        loading={this.loading}
                    />
                )}
                {this.prodName === '圆钢' && (
                    <Bound
                        loading={this.loading}
                        list={this.list}
                        pageNum={this.pageNum}
                        pageSize={this.pageSize}
                        total={this.total}
                        onShowSizeChange={this.onShowSizeChange}
                        onTableChange={this.onTableChange}
                        del={this.del}
                        modify={this.modify}
                    />
                )}
            </Fragment>
        );
    }
}

export default Mech;