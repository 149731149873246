import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Checkbox, Input, DatePicker, Select } from 'antd';
import styles from './index.module.scss';
import moment from 'moment';

const { Option } = Select;

const boundOptions = [
    { label: '全部', value: '', disabled: true },
    { label: '⌀14', value: '14' },
    { label: '⌀16', value: '16' },
    { label: '⌀18', value: '18' },
    { label: '⌀20', value: '20' },
    { label: '⌀22', value: '22' },
    { label: '⌀25', value: '25' },
    { label: '⌀28', value: '28' },
    { label: '⌀30', value: '30' },
    { label: '⌀32', value: '32' },
    { label: '⌀34', value: '34' },
    { label: '⌀35', value: '35' },
    { label: '⌀36', value: '36' },
    { label: '⌀38', value: '38' },
    { label: '⌀40', value: '40' },
    { label: '⌀42', value: '42' },
    { label: '⌀45', value: '45' },
    { label: '⌀48', value: '48' },
    { label: '⌀50', value: '50' },
    { label: '⌀55', value: '55' },
    { label: '⌀56', value: '56' },
    { label: '⌀60', value: '60' },
];

const deformedOptions = [
    { label: '全部', value: '', disabled: true },
    { label: '⌀12', value: '12' },
    { label: '⌀14', value: '14' },
    { label: '⌀16', value: '16' },
    { label: '⌀18', value: '18' },
    { label: '⌀20', value: '20' },
    { label: '⌀22', value: '22' },
    { label: '⌀25', value: '25' },
    { label: '⌀28', value: '28' },
    { label: '⌀30', value: '30' },
    { label: '⌀32', value: '32' },
    { label: '⌀34', value: '34' },
    { label: '⌀35', value: '35' },
    { label: '⌀36', value: '36' },
    { label: '⌀38', value: '38' },
    { label: '⌀40', value: '40' },
    { label: '⌀42', value: '42' },
];

const billetOptions = [
    { label: '全部', value: '', disabled: true },
    { label: '150*150', value: '150*150' },
];

@observer
class ProductSearch extends Component {

    @observable
    prodName = '圆钢';

    @observable
    date = new Array(2);

    @observable
    param = {
        spec: []
    };

    @observable
    shiftList = [];

    componentDidMount() {
        this.prodName = this.props.prodName;
    }

    componentWillReceiveProps(nextProps) {
        if (this.prodName !== nextProps.prodName) {
            this.prodName = nextProps.prodName;
        }
        if (this.shiftList !== nextProps.shiftList) {
            this.shiftList = nextProps.shiftList;
        }
    }

    search = () => {
        let { search } = this.props;
        search && search(this.param);
    }

    clear = () => {
        this.date = [];
        this.param = {
            spec: []
        };
        let { search } = this.props;
        search && search({});
    }

    onSpecChange = (checkedValues) => {
        if (checkedValues && checkedValues.length > 0) {
            for (let index = 0; index < checkedValues.length; index++) {
                if (checkedValues[index] === '') {
                    checkedValues.splice(index, 1);
                    break;
                }
            }
            this.param.spec = checkedValues;
        }
        else {
            this.param.spec = [''];
        }
    }

    onBatchNoChange = (e) => {
        this.param.batchNo = e.target.value;
    }

    onProductTimeChange = (dates, dateStrings) => {
        this.date[0] = dates[0];
        this.date[1] = dates[1];
        this.param.startTime = moment(dateStrings[0]).format('YYYY-MM-DD');
        this.param.endTime = moment(dateStrings[1]).format('YYYY-MM-DD');
    }

    onShiftChange = (value) => {
        this.param.shift = value;
    }

    render() {
        const { RangePicker } = DatePicker;
        return (
            <Fragment>
                <div className={styles.specBox}>
                    <div className={styles.specLabel}>规格:</div>
                    <div className={styles.specValues}>
                        {this.prodName === '圆钢' && (
                            <Checkbox.Group
                                options={boundOptions}
                                value={this.param.spec}
                                onChange={this.onSpecChange}
                            />
                        )}
                        {this.prodName === '螺纹钢' && (
                            <Checkbox.Group
                                options={deformedOptions}
                                value={this.param.spec}
                                onChange={this.onSpecChange}
                            />
                        )}
                        {this.prodName === '钢坯' && (
                            <Checkbox.Group
                                options={billetOptions}
                                value={this.param.spec}
                                onChange={this.onSpecChange}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>{this.prodName === '钢坯' ? '炉批号:' : '轧钢批号:'}</div>
                    <div className={styles.value}>
                        <Input
                            style={{ width: '180px' }}
                            maxLength={20}
                            value={this.param.batchNo}
                            allowClear
                            onChange={this.onBatchNoChange}
                        />
                    </div>
                    <div className={styles.label}>班次:</div>
                    <div className={styles.value}>
                        <Select
                            value={this.param.shift}
                            style={{ width: 180 }}
                            loading={this.shiftListLoading}
                            onChange={this.onShiftChange}
                            allowClear={true}
                            placeholder='请选择'
                        >
                            {(this.shiftList && this.shiftList.length > 0) && (
                                this.shiftList.map((item, index) => {
                                    return (
                                        <Option key={index} value={item}>{item}</Option>
                                    );
                                })
                            )}
                        </Select>
                    </div>
                    <div className={styles.label}>生产日期:</div>
                    <div>
                        <RangePicker
                            style={{ width: '240px' }}
                            allowClear
                            onChange={this.onProductTimeChange}
                            value={this.date}
                        />
                    </div>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={this.search}
                        icon="search"
                        style={{ marginLeft: 40 }}
                    >
                        搜索
                    </Button>
                    <Button style={{ marginLeft: 16 }} onClick={this.clear}>
                        重置
                    </Button>
                </div>
            </Fragment>
        );
    }
}

export default ProductSearch;