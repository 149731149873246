import React, { Component } from 'react';
import { Form, Input, Button, Select } from 'antd';
// @ts-ignore
import styles from './index.module.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

const { Option } = Select;

@observer
class AddGrade extends Component {

    @observable loading = false;

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { insertGrade } = this.props;
                insertGrade && insertGrade(values);
                this.props.form.resetFields();
            }
        });
    };

    render() {
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        let { getFieldDecorator, getFieldsError } = this.props.form;
        return (
            <div>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <div className={styles.row}>
                        <Form.Item label='产品牌号'>
                            {getFieldDecorator('grade',
                                {
                                    rules: [{ required: true, message: '请输入牌号' },],
                                })
                                (<Input maxLength={100} style={{ width: 250 }} placeholder='请输入牌号' />)}
                        </Form.Item>
                    </div>
                    <div className={styles.row}>
                        <Form.Item label='产品名称'>
                            {getFieldDecorator('prodName',
                                {
                                    rules: [
                                        { required: true, message: '请选择产品' },
                                    ],
                                })
                                (<Select
                                    style={{ width: 250 }}
                                    placeholder='请选择产品'
                                    allowClear={true}
                                >
                                    <Option value='圆钢'>圆钢</Option>
                                    <Option value='螺纹钢'>螺纹钢</Option>
                                    <Option value='钢坯'>钢坯</Option>
                                </Select>)}
                        </Form.Item>
                    </div>
                    <div className={styles.row}>
                        <Form.Item label='检查标准'>
                            {getFieldDecorator('standard',
                                {
                                    rules: [
                                        { required: true, message: '请输入检查标准' },
                                    ],
                                })
                                (<Input maxLength={100} style={{ width: 250 }} placeholder='请输入检查标准' />)}
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', marginTop: 20, alignItems: 'center', justifyContent: 'center' }}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={this.hasErrors(getFieldsError()) || this.props.addSubmitting}
                                loading={this.props.addSubmitting}
                                style={{ minWidth: 100 }}
                            >
                                确定
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(AddGrade);